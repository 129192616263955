import { IsLoadingComponent, useQuery } from '@platform/front-lib';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES, TOOL_MODULES } from '../constants';
import { useStores } from '../stores';

interface IRelatedLinkHandler {}

interface IRelatedLinkHandlerQuery {
  moduleCode?: string;
  educationEntityId?: string;
}

type THistory = ReturnType<typeof useHistory>;

function fallback(history: THistory) {
  history.push(generatePath(ROUTES.MODULE_VIEW));
}

function viewBase(history: THistory, urlSegmentId: string) {
  history.push(
    generatePath(ROUTES.VIEW_KNOWLEDGE_TUTORIAL_LIST_ROOT, {
      baseId: urlSegmentId,
    }),
  );
}
function notFoundBase(history: THistory, educationEntityId: string) {
  history.push(
    generatePath(ROUTES.VIEW_KNOWLEDGE_BASE_NOT_FOUND, {
      educationEntityId,
    }),
  );
}

export const RelatedLinkHandler: React.FC<IRelatedLinkHandler> = observer(
  () => {
    const query: IRelatedLinkHandlerQuery = useQuery();
    const history = useHistory();

    const {
      viewStore: {
        knowledgeBaseStore: { getOneByEducationEntity },
      },
    } = useStores();

    const isViewModule = query?.moduleCode === TOOL_MODULES.VIEW;

    if (!query.moduleCode || !query.educationEntityId) {
      fallback(history);
    }

    if (isViewModule) {
      if (!query.educationEntityId) {
        fallback(history);
      }
    }

    React.useEffect(() => {
      if (isViewModule && !!query?.educationEntityId) {
        getOneByEducationEntity(
          { id: query.educationEntityId },
          {
            failFunc: () => {
              fallback(history);
            },
            successFunc: (data) => {
              if (data?.urlSegmentId) {
                viewBase(history, data.urlSegmentId);
                return;
              }

              if (query.educationEntityId) {
                notFoundBase(history, query.educationEntityId);
                return;
              }
              fallback(history);
            },
          },
        );
      }
    }, [
      getOneByEducationEntity,
      history,
      isViewModule,
      query.educationEntityId,
    ]);

    return <IsLoadingComponent isLoading />;
  },
);
