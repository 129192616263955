import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { IKnowledgeTutorial } from '../../../../../../models';
import { useStylesRootCard } from './styles';

import { NavLink, useRouteMatch } from 'react-router-dom';
import { AnvIcon, AnvUtils } from '@platform/front-lib';
import { AnvCardTitle } from './card-title';
import { AnvChipUnpublished } from '../../../../../common/chip-unpublished';
import { AnvCardContent } from '../content';
import { useIntl } from 'react-intl';
import { useStyles } from '../styles';

interface IProps {
  tutorial: IKnowledgeTutorial;
  dataMapId: Record<string, IKnowledgeTutorial>;
}

const AnvTutorialCard: React.FC<IProps> = ({ dataMapId, tutorial }) => {
  const theme = useTheme();
  const { locale } = useIntl();

  const { theme: tutorialTheme, icon: tutorialIcon } = tutorial;

  const toolTheme = {
    toolColors: tutorialTheme,
    theme: theme,
  };

  const classesRoot = useStyles();
  const classes = useStylesRootCard(toolTheme);

  const { url } = useRouteMatch();

  const screenSM = useMediaQuery(theme.breakpoints.down('sm'));

  if (!tutorial.urlSegment) {
    return null;
  }

  const subTutorials = tutorial.tutorials
    ?.map((tutorialId) => {
      return dataMapId[tutorialId];
    })
    .filter((item) => !!item);

  return (
    <Grid
      item
      key={tutorial._id}
      style={{ marginBottom: screenSM ? '16px' : 'unset' }}
    >
      <Grid
        container={screenSM ? true : undefined}
        item
        direction={screenSM ? 'row' : undefined}
        wrap={screenSM ? 'nowrap' : undefined}
        className={classes.root}
      >
        <Grid className={classes.colorRectangleAndIconWrapper}>
          <div className={classes.colorRectangle} />

          <div className={classes.iconWrapper}>
            {tutorialIcon && <AnvIcon icon={tutorialIcon} size={40} />}
          </div>
        </Grid>

        <Grid className={classesRoot.contentWrapper}>
          <NavLink
            to={AnvUtils.cleanUrl(`${url}/${tutorial.urlSegment}`)}
            className={classesRoot.link}
          >
            <AnvCardTitle
              title={
                tutorial.title ? (
                  <>
                    {AnvUtils.getNameByLangNullable(tutorial, 'title', locale)}
                    {!tutorial.published && <AnvChipUnpublished short />}
                  </>
                ) : (
                  ''
                )
              }
            />
          </NavLink>

          {!!subTutorials?.length && (
            <AnvCardContent
              parentSlug={tutorial.urlSegment}
              subTutorials={subTutorials}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AnvTutorialCard;
