import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import Routes from './routes';

import { rootStore, StoresContext } from './stores/root-store';
import { IntlProvider } from './providers';
import AnvMuiThemeProvider from './providers/mui-theme';
import AnvHelmetProviderConnected from './providers/helmet-provider';
import ErrorBoundary from './route-handlers/ErrorBoundary';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <StoresContext.Provider value={rootStore}>
        <IntlProvider>
          <AnvMuiThemeProvider>
            <AnvHelmetProviderConnected>
              <Routes />
            </AnvHelmetProviderConnected>
          </AnvMuiThemeProvider>
        </IntlProvider>
      </StoresContext.Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
