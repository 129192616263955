import KnowledgeBaseStore from './knowledge-base';
import TutorialsStore from './tutorials';
import ChaptersStore from './chapters';
import { RootCommonStore } from './root-common-store';

export const BuildStore = {
  knowledgeBaseStore: KnowledgeBaseStore,
  tutorialsStore: TutorialsStore,
  chaptersStore: ChaptersStore,
  rootStore: new RootCommonStore({ isView: false }),
};
