import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    unpublishedChip: {
      marginLeft: theme.spacing(1),
      height: 18,
    },
  }),
);
