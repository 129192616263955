import Cookies from 'js-cookie';

import { extractRootDomain } from './http';
import { AUTH_PROFILE_KEY, AUTH_TOKEN_KEY, envConst } from '../constants';
import LocalStorage from '../stores/_helpers/storage';
import { rootStore } from 'src/stores/root-store';

export function removeCookieTokenAndProfile({ isOperator = false } = {}) {
  const operatorPostfix = isOperator ? '-operator' : '';

  Cookies.remove('anvilar-refresh' + operatorPostfix, {
    domain: extractRootDomain(envConst.launchpadUrl),
  });
  Cookies.remove('anvilar-token' + operatorPostfix, {
    domain: extractRootDomain(envConst.launchpadUrl),
  });
  Cookies.remove('anvilar-profile' + operatorPostfix, {
    domain: extractRootDomain(envConst.launchpadUrl),
  });

  try {
    rootStore.authStores.reset();
  } catch (e) {
    console.log('----', { e });
  }
}

export function getCookieToken({ isOperator = false } = {}) {
  const operatorPostfix = isOperator ? '-operator' : '';
  const cookieKey = 'anvilar-token' + operatorPostfix;

  const token = Cookies.get(cookieKey);

  if (!token && process.env.NODE_ENV !== 'production') {
    return LocalStorage.get(AUTH_TOKEN_KEY);
  }

  return token;
}

export function getCookieTokenRefresh({ isOperator = false } = {}) {
  const operatorPostfix = isOperator ? '-operator' : '';
  const cookieKey = 'anvilar-refresh' + operatorPostfix;
  const token = Cookies.get(cookieKey);

  if (!token && process.env.NODE_ENV !== 'production') {
    return LocalStorage.get(cookieKey);
  }

  return token;
}

export function getCookieProfile({ isOperator = false } = {}) {
  const operatorPostfix = isOperator ? '-operator' : '';
  const cookieKey = 'anvilar-profile' + operatorPostfix;

  try {
    const profile = Cookies.get(cookieKey);

    if (!profile && process.env.NODE_ENV !== 'production') {
      return LocalStorage.getJSON(AUTH_PROFILE_KEY + operatorPostfix);
    }

    if (!profile) {
      return null;
    }

    return JSON.parse(profile);
  } catch (err) {
    return null;
  }
}
