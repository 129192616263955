import { Grid } from '@material-ui/core';
import React from 'react';
import { useStylesContent } from './styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { IKnowledgeTutorial } from '../../../../../../models';
import { AnvChipUnpublished } from '../../../../../common/chip-unpublished';
import { AnvIcon, AnvUtils } from '@platform/front-lib';
import clsx from 'clsx';

interface IProps {
  parentSlug: string;
  subTutorials?: IKnowledgeTutorial[];
  isCommon?: boolean;
}

const MAX_SUB_TUTORIAL = 3;

export const AnvCardContent: React.FC<IProps> = ({
  parentSlug,
  subTutorials,
  isCommon = false,
}) => {
  const { locale } = useIntl();

  const classes = useStylesContent();
  const { url } = useRouteMatch();

  const isSubTutorialsMax =
    subTutorials && subTutorials?.length > MAX_SUB_TUTORIAL;

  const link = (urlSegment?: string) => {
    if (!!urlSegment) {
      return `${url}/${parentSlug}/${urlSegment}`;
    }

    return `${url}`;
  };

  return (
    <Grid
      container
      item
      spacing={1}
      direction={'column'}
      xs={12}
      className={clsx({
        [classes.contentWrapper]: !isCommon,
        [classes.contentWrapperCommon]: isCommon,
      })}
    >
      {subTutorials?.map?.((item, index) => {
        if (!item) {
          return null;
        }

        if (index + 1 > MAX_SUB_TUTORIAL) {
          return null;
        }

        return (
          <Grid
            key={item._id}
            item
            className={clsx({
              [classes.itemCommon]: isCommon,
            })}
          >
            <NavLink
              key={index}
              to={link(item?.urlSegment)}
              className={clsx(classes.link, {
                [classes.linkCommon]: isCommon,
              })}
            >
              {AnvUtils.getNameByLangNullable(item, 'title', locale)}

              {!item.published && <AnvChipUnpublished short />}

              {isCommon && (
                <span className={classes.linkChevron}>
                  <AnvIcon icon={'chevronRight'} size={16} />
                </span>
              )}
            </NavLink>
          </Grid>
        );
      })}

      {isSubTutorialsMax && (
        <NavLink
          to={`${url}/${parentSlug}`}
          className={clsx(classes.viewAll, {
            [classes.viewAllCommon]: isCommon,
          })}
        >
          <FormattedMessage
            id="KNOWLEDGE_BASE.VIEW.TUTORIAL.CARD.VIEW_ALL.LINK"
            defaultMessage="View all"
          />
        </NavLink>
      )}
    </Grid>
  );
};
