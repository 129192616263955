import {
  AnvSchoolName,
  AnvUtils,
  Constants,
  ISelectOption,
} from '@platform/front-lib';
import { IAccessScopes } from '../../../models/access-scopes';
import { IOrganizationType } from '../../../models/organization';
import { IFormatMessage } from 'src/models/_helpers/react-intl';
import { Column } from 'react-table';
import CustomPublishedRow from '../../common/custom-published-row';
import PublicLink from '../../../components/tool/public-link';

export function orgListColumns(
  locale: string | undefined,
  formatMessage: IFormatMessage,
  {
    // accessScopesOptions,
    // accessScopesMap,
    organizationTypesOptions,
    organizationTypesMap,
    isView = false,
    toolUrl,
  }: {
    accessScopesOptions?: ISelectOption[];
    accessScopesMap?: Record<string, IAccessScopes> | null;
    organizationTypesOptions?: ISelectOption[];
    organizationTypesMap: Record<string, IOrganizationType> | null;
    isView?: boolean;
    toolUrl: string;
  },
) {
  const columns: Record<string, any>[] = [
    {
      sortable: true,
      filterable: true,
      width: 200,
      Header: () => {
        return formatMessage({
          id: 'KNOWLEDGE_BASE.COMMON.LIST.TH.ORG',
          defaultMessage: 'Organization',
        });
      },
      headerString: formatMessage({
        id: 'KNOWLEDGE_BASE.COMMON.LIST.TH.ORG',
        defaultMessage: 'Organization',
      }),
      accessor: 'name',
      id: 'name',

      Cell: ({ cell }: Record<string, any>) => {
        const entity = cell?.row?.original;
        entity.address = entity.address
          ? entity.address
          : entity.mailingAddress;

        return (
          <div>
            <AnvSchoolName school={entity} />
            {/*<AnvSchoolName school={{ cell?.row?.original }} />*/}
          </div>
        );
      },
    },
    {
      width: 250,
      sortable: true,
      filterable: true,
      filterType: Constants.FILTER_TYPES.select,
      filterOptions: organizationTypesOptions,
      Header: () => {
        return formatMessage({
          id: 'KNOWLEDGE_BASE.COMMON.LIST.TH.ORG_TYPE',
          defaultMessage: 'Organization Type',
        });
      },
      headerString: ' ',
      accessor: 'organizationRef',
      id: 'organizationType',

      Cell: ({ cell }: Record<string, any>) => {
        const orgType = cell?.row?.original.organizationType;

        const title = orgType
          ? AnvUtils.getNameByLangNullable(
              organizationTypesMap?.[orgType],
              'description',
              locale,
            )
          : formatMessage({
              id: 'KNOWLEDGE_BASE.COMMON.LIST.ORG_TYPE.SCHOOL',
              defaultMessage: 'School',
            });

        return <div>{title}</div>;
      },
    },
    {
      Header: () => {
        return formatMessage({
          id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.LIST.PUBLIC_URL.LABEL',
          defaultMessage: 'Public url',
        });
      },
      headerString: formatMessage({
        id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.LIST.PUBLIC_URL.LABEL',
        defaultMessage: 'Public url',
      }),

      id: '_id',

      Cell: ({ row }: Record<string, any>) => {
        const published = row?.original?.basePublished;
        const urlSegmentId = row?.original?.baseSegment;
        if (published) {
          return <PublicLink toolUrl={toolUrl} baseUrlSegment={urlSegmentId} />;
        }
        return null;
      },
    },

    {
      width: 250,
      Header: () => {
        return formatMessage({
          id: 'KNOWLEDGE_BASE.COMMON.LIST.TH.PUBLISHED',
          defaultMessage: 'Published',
        });
      },
      accessor: 'basePublished',
      id: 'basePublished',

      Cell: ({ cell }: Record<string, any>) => {
        const basePublished = cell?.row?.original.basePublished;

        return <CustomPublishedRow published={!!basePublished} />;
      },
    },
  ];

  return columns as Column<Record<string, any>>[];
}
