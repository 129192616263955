import React from 'react';
import { observer } from 'mobx-react-lite';

import { generatePath, useHistory, useParams } from 'react-router-dom';

import { useStores } from 'src/stores/root-store';
import { ROUTES } from 'src/constants';
import IsLoadingComponent from 'src/components/layout/loading';
import { IKnowledgeBase } from '../../../models';

export const PageBuildNewBase = observer(() => {
  const { id } = useParams<Record<string, string>>();
  const history = useHistory();

  const {
    buildStore: {
      rootStore: { createNewRootBase },
    },
  } = useStores();

  React.useEffect(() => {
    if (id) {
      const successFunc = (response: IKnowledgeBase) => {
        const url = generatePath(ROUTES.KNOWLEDGE_TUTORIAL_LIST, {
          baseId: response?.urlSegmentId,
        });
        history.replace(url);
      };

      createNewRootBase({ id: id }, { successFunc });
    }
  }, [id, createNewRootBase, history]);

  return <IsLoadingComponent isLoading />;
});
