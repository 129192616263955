import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';
import { Redirect } from 'react-router-dom';
import { ROUTES, TOOL_MODULES } from '../../../constants';

interface IBuildRootProps {}

export const BuildRoot: React.FC<IBuildRootProps> = observer(() => {
  const {
    authStores: { isOperatorLoggedIn, isLoggedIn, modulesCodes },
  } = useStores();

  if (isOperatorLoggedIn) {
    return <Redirect to={ROUTES.MODULE_BUILD_OPERATOR} />;
  }

  if (isLoggedIn && modulesCodes.indexOf(TOOL_MODULES.BUILD) > -1) {
    return <Redirect to={ROUTES.MODULE_BUILD_USER_LIST} />;
  }

  return <Redirect to={ROUTES.MODULE_VIEW} />;
});
