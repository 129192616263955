import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    searchControl: {
      margin: 0,
      width: 760,
    },

    unpublishedChip: {
      marginLeft: theme.spacing(1),
      height: 18,
    },

    link: {
      textDecoration: 'none',
    },

    contentWrapper: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(4.5),
        marginTop: theme.spacing(2),
      },
    },

    htmlWrapper: {
      width: 'inherit',
      '& > pre': {
        whiteSpace: 'break-spaces',
      },
    },
  }),
);
