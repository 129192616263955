import { action, makeObservable, observable } from 'mobx';

import { IBaseCommonPayload, IKnowledgeBase } from 'src/models';
import { ICommonService } from '../../models/common/services';

import { KnowledgeBaseService } from '../../service/knowledge';

import { defaultPagination, IActionMeta, IPagination } from '../_helpers';

export class CommonBaseStore {
  baseService: KnowledgeBaseService;

  error: Record<string, any> | null | string = null;
  dataMap: Record<string, IKnowledgeBase> = {};
  dataMapId: Record<string, IKnowledgeBase> = {};

  data: IKnowledgeBase[] | null = null;
  pagination: IPagination = defaultPagination;
  sort: string = '';
  filter: any[] | Record<string, any> = {};

  isFetching: boolean = false;
  isSaving: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  resetErrors = () => {
    this.error = null;
    this.isError = false;
  }

  getOne = async ({ id }: IBaseCommonPayload, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.baseService.getOne({ id });

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.dataMap[`${response.urlSegmentId}`] = response;
      this.dataMapId[response._id] = response;
    });
  };

  getOneByEducationEntity = async (
    { id }: IBaseCommonPayload,
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.baseService.getOneByEducationEntity({
      id,
    });

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
        meta?.failFunc?.(this.error);
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.(response);
    });
  };

  getEntities = async (payload: { [index: string]: any }) => {
    this.setLoading();

    const [error, response] = await this.baseService.getAll(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      const { docs, distinct, ...pagination } = response;
      this.pagination = pagination;
      this.data = docs || [];

      this.data?.forEach?.((item) => {
        if (item._id) {
          this.dataMap[`${item.urlSegmentId}`] = item;

          this.dataMapId[item._id] = item;
        }
      });
    });
  };

  clearErrors = () => {
    this.error = null;
    this.isError = false;
  };

  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };

  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };

  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  reset() {
    this.data = null;
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  }

  constructor({ isView = false }: ICommonService) {
    this.baseService = new KnowledgeBaseService({ isView });

    makeObservable(this, {
      dataMap: observable,
      dataMapId: observable,
      data: observable,
      pagination: observable,
      sort: observable,
      filter: observable,

      isFetching: observable,
      isSaving: observable,
      isLoaded: observable,
      isError: observable,
      error: observable,

      getOne: action,
      getEntities: action,

      setLoading: action,
      resetErrors: action,
      setError: action,
      setLoaded: action,
      reset: action,
      clearErrors: action,
    });
  }
}
