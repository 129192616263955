import { IGetList } from 'src/service/interfaces';
import { axiosCatch } from 'src/service/utils';
import { axiosInstance } from '@platform/front-lib';
import { IKnowledgeTutorial } from 'src/models';

const axios = axiosInstance;
interface IGetListNew extends IGetList {
  parentId: string;
}

// interface IKnowledgeTutorialNew extends IKnowledgeTutorial {
//   parentId: string;
// }

export class ChaptersService {
  private readonly apiUrl =
    process.env.REACT_APP_API_TOOL_ENDPOINT + '/view/chapter';

  constructor({ isView = false }: { isView: boolean }) {
    this.apiUrl = isView
      ? process.env.REACT_APP_API_TOOL_ENDPOINT + '/view/chapter'
      : process.env.REACT_APP_API_TOOL_ENDPOINT + '/build/chapter';
  }

  async getAll(data: IGetListNew) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/list`, {
        params: data,
      }),
    );
  }

  async getOne({ id }: { id: string }) {
    return axiosCatch(axios.get(`${this.apiUrl}/${id}`, {}));
  }

  async create(payload: IKnowledgeTutorial) {
    return axiosCatch(axios.post(`${this.apiUrl}`, payload, {}));
  }

  async update(payload: IKnowledgeTutorial) {
    const { _id: tutorialId, base: baseId, ...data } = payload;
    return axiosCatch(axios.patch(`${this.apiUrl}/${tutorialId}`, data, {}));
  }

  async publish({ id }: { id: string }) {
    return axiosCatch(axios.patch(`${this.apiUrl}/publish/${id}`));
  }
  async unpublish({ id }: { id: string }) {
    return axiosCatch(axios.patch(`${this.apiUrl}/unpublish/${id}`));
  }

  async updatePositions(payload: any) {
    return axiosCatch(
      axios.patch(`${this.apiUrl}/update-positions`, payload, {}),
    );
  }

  async delete({ id, parentId }: { id: string; parentId: string }) {
    return axiosCatch(
      axios.delete(`${this.apiUrl}/${id}`, {
        params: {
          parentId: parentId,
        },
      }),
    );
  }
}

const chaptersService = new ChaptersService({ isView: false });

export default chaptersService;
