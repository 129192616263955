import { makeStyles } from '@material-ui/core';

import { BORDER_RADIUS } from 'src/styles/constants';
import { ICommonTheme } from '../../../../../../models';

interface IStyleProps {
  toolColors?: ICommonTheme | null;
  theme: any;
}

const CARD_BOX_SHADOW =
  '0px 0px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.18)';

export const useStylesRootCard = makeStyles({
  root: ({ theme, toolColors }: IStyleProps) => ({
    height: 276,
    width: 238,
    boxShadow: CARD_BOX_SHADOW,
    borderRadius: BORDER_RADIUS,
    [theme.breakpoints.down('sm')]: {
      height: 196,
      width: '100%',
    },
    '&.active': {
      backgroundColor: toolColors?.brandColorLight,
      borderRadius: BORDER_RADIUS,
    },
  }),

  cardWrapper: ({ theme }: IStyleProps) => ({
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }),

  colorRectangle: ({ theme, toolColors }: IStyleProps) => ({
    borderRadius: '6px 6px 0px 0px',
    backgroundColor: toolColors?.brandColorLight || theme.palette.brand.light,
    color: toolColors?.brandColorLight || theme.palette.brand.main,
    height: 64,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '6px 0px 0px 6px',
      height: '100%',
      width: 48,
    },
  }),
  colorRectangleAndIconWrapper: ({ theme, toolColors }: IStyleProps) => ({
    position: 'relative',
    borderRadius: '6px 6px 0px 0px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '6px 0px 0px 6px',
    },
  }),

  iconWrapper: ({ theme, toolColors }: IStyleProps) => ({
    position: 'absolute',
    top: 16,
    left: 24,
    backgroundColor: toolColors?.brandColor || theme.palette.brand.main,
    borderRadius: '50%',
    color: theme.palette.common.white,
    padding: theme.spacing(1.5),
    width: 64,
    height: 64,
    [theme.breakpoints.down('sm')]: {
      left: 16,
    },
  }),
});
