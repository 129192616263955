import { makeStyles } from '@material-ui/core/styles';
import { ICommonTheme } from '../../../../../models';
import { BORDER_RADIUS } from '../../../../../styles/constants';

import mask from './assets/mask.png';
interface IStyleProps {
  tutorialTheme?: ICommonTheme | null;
  theme: any;
}

export const useStyles = makeStyles({
  header: ({ theme }: IStyleProps) => ({
    '&&': {
      position: 'relative',
      padding: theme.spacing(2, 3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),

      borderRadius: BORDER_RADIUS,
      backgroundImage: `url(${mask})`,

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        borderRadius: `0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px`,
      },
    },
  }),
  paperRoot: ({ theme }: IStyleProps) => ({
    padding: theme.spacing(3),
  }),
  titleWrapper: ({ theme }: IStyleProps) => ({
    marginBottom: theme.spacing(2),
  }),
  descriptionWrapper: () => ({
    '& > p': {
      margin: 0,
    },
  }),

  unpublishedChip: ({ theme }: IStyleProps) => ({
    marginLeft: theme.spacing(1),
    height: 18,
  }),

  tutorialTitle: () => ({
    margin: 0,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.18px',
  }),
  link: ({ theme }: IStyleProps) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '14px',
    color: theme.palette.brand.main,
    textDecoration: 'none',
  }),

  iconWrapper: ({ theme, tutorialTheme }: IStyleProps) => ({
    width: 56,
    height: 56,
    padding: 16,
    backgroundColor:
      tutorialTheme?.brandColorLight || theme.palette.brand.light,
    borderRadius: '50%',
    zIndex: 5,
    color: tutorialTheme?.brandColor || theme.palette.brand.main,
    marginRight: theme.spacing(2),
  }),
});
