import { action, makeObservable, observable } from 'mobx';

import { IKnowledgeChapter } from 'src/models';
import { ICommonService } from '../../models/common/services';
import { IGetList } from '../../service/interfaces';

import { ChaptersService } from '../../service/knowledge';

import { IActionMeta } from '../_helpers';

interface IGetListNew extends IGetList {
  parentId: string;
  language?: string;
  baseId?: string;
}

export class CommonChaptersStore {
  chaptersService: ChaptersService;

  error: Record<string, any> | null | string = null;
  dataMap: Record<string, IKnowledgeChapter> = {};
  data: IKnowledgeChapter[] | null = null;

  isFetching: boolean = false;
  isSaving: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  getOne = async ({ id }: { id: string }, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.chaptersService.getOne({ id });

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.dataMap[response._id] = response;
    });
  };

  getEntities = async (payload: IGetListNew) => {
    this.setLoading();

    // todo module
    const [error, response] = await this.chaptersService.getAll(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.data = response.knowledgeChapters || [];

      this.data?.forEach?.((item) => {
        this.dataMap[item?._id || ''] = item;
      });
    });
  };

  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };

  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };

  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  reset() {
    this.data = null;
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  }

  constructor({ isView = false }: ICommonService) {
    this.chaptersService = new ChaptersService({ isView });

    makeObservable(this, {
      error: observable,
      dataMap: observable,
      data: observable,

      isFetching: observable,
      isSaving: observable,
      isLoaded: observable,
      isError: observable,

      getOne: action,
      getEntities: action,

      setLoading: action,
      setError: action,
      setLoaded: action,
      reset: action,
    });
  }
}
