import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    contentWrapper: {
      marginBottom: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(6),
      },

      '&:last-child': {
        marginBottom: 'unset',
      },
    },

    unpublishedChip: {
      marginLeft: theme.spacing(1),
      height: 18,
    },
  }),
);
