import React from 'react';

import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { Grid } from '@material-ui/core';

import { useStores } from 'src/stores/root-store';

import { AnvFilterButton } from '@platform/front-lib';

import { ModuleContext } from 'src/pages/common/module-context';

import { ListFilter } from './ListFilter';
import { useModal } from 'src/utils/hooks';
import { useStyles } from './styles';

interface IProps {
  total?: number;
}

export const ListToolbarFilterCounter: React.FC<IProps> = observer(
  ({ total }) => {
    const classes = useStyles();

    const {
      isOpen: openFilters,
      handleToggleModal: handleToggleFilters,
    } = useModal();

    const {
      authStores: { modulesFilter, setAccessFilter },
    } = useStores();

    const module = React.useContext(ModuleContext);

    // @ts-ignore
    const accessFilter = modulesFilter[module];

    const handleResetFilter = () => {
      setAccessFilter(module);
    };

    return (
      <Grid
        className={classes.toolbarFilterCounter}
        container
        justify={'space-between'}
        alignItems={'center'}
      >
        <AnvFilterButton
          handleToggleFilters={handleToggleFilters}
          handleResetFilter={handleResetFilter}
          accessFilter={accessFilter}
          filterText={
            <FormattedMessage
              id="KNOWLEDGE_BASE.COMMON.LABEL.FILTER"
              defaultMessage="Filter"
            />
          }
        />

        {!!total && (
          <Grid item className={classes.toolbarStatusContainer}>
            <FormattedMessage id="APP.COMMON.TOTAL" defaultMessage="Total" />
            {`: `}
            {total}
          </Grid>
        )}

        <ListFilter
          openFilters={openFilters}
          handleToggleFilters={handleToggleFilters}
        />
      </Grid>
    );
  },
);
