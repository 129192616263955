import React from 'react';

import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';

import { FormattedMessage } from 'react-intl';
import { AnvButton, AnvIcon } from '@platform/front-lib';

interface IDrawerFooter {
  profile?: any;
  logOut: () => void;
}

const DrawerFooter: React.FC<IDrawerFooter> = ({ profile, logOut }) => {
  const classes = useStyles();
  const handleLogOut = () => {
    logOut();
  };
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid
        container
        item
        sm={8}
        xs={7}
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.user}
      >
        {' '}
        <AnvIcon icon={'user'} size={24} style={{ marginRight: '8px' }} />{' '}
        {profile?.firstName} {profile?.lastName}
      </Grid>
      <Grid item sm={4} xs={5}>
        {' '}
        <AnvButton
          style={{
            width: '100%',
            color: 'rgba(0, 0, 0, 0.7)',
            fontWeight: 400,
          }}
          startIcon={<AnvIcon icon={'logOutNew'} size={24} />}
          onClick={handleLogOut}
        >
          {' '}
          <FormattedMessage id="APP.LOG_OUT" defaultMessage="Log Out" />{' '}
        </AnvButton>{' '}
      </Grid>
    </Grid>
  );
};

export default DrawerFooter;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 56,
      borderTop: '1px solid rgba(3, 57, 61, 0.15)',
      marginTop: 32,
    },
    user: {
      padding: theme.spacing(2),
    },
    logOut: {
      width: '100%',
      color: 'rgba(0, 0, 0, 0.7)',
      fontWeight: 400,
    },
  }),
);
