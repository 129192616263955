import { axiosCatch } from 'src/service/utils';
import { axiosInstance } from '@platform/front-lib';
import { IKnowledgeTutorial } from 'src/models';
import { ICommonService } from '../../../models/common';
import { IGetTutorials } from '../../../stores/knowledge/tutorials-common';

const axios = axiosInstance;

interface IGetAllByBase {
  baseId: string;
}

interface IGetAllChain {
  baseId: string;
  tutorialsSegments: string[];
}

interface IKnowledgeTutorialNew extends IKnowledgeTutorial {
  parentId: string;
}

export class TutorialsService {
  private readonly apiUrl: string;

  constructor({ isView }: ICommonService) {
    this.apiUrl = isView
      ? process.env.REACT_APP_API_TOOL_ENDPOINT + '/view/tutorial'
      : process.env.REACT_APP_API_TOOL_ENDPOINT + '/build/tutorial';
  }

  async getAll(data: IGetTutorials) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/list`, {
        params: data,
      }),
    );
  }
  async getAllByBase(data: IGetAllByBase) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/get-all`, {
        params: data,
      }),
    );
  }

  async getAllChain(data: IGetAllChain) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/get-chain`, {
        params: data,
      }),
    );
  }

  // async getOne(baseId: string, id: string) {
  async getOne(baseId: string, id: string) {
    return axiosCatch(axios.get(`${this.apiUrl}/${id}`, {}));
  }

  async create(payload: IKnowledgeTutorialNew) {
    return axiosCatch(axios.post(`${this.apiUrl}`, payload, {}));
  }

  async update(payload: IKnowledgeTutorial) {
    const { _id: tutorialId, base: baseId, ...data } = payload;
    return axiosCatch(axios.patch(`${this.apiUrl}/${tutorialId}`, data, {}));
  }

  async updatePositions(payload: any) {
    return axiosCatch(
      axios.patch(`${this.apiUrl}/update-positions`, payload, {}),
    );
  }

  async checkPublish({ id }: { id: string }) {
    return axiosCatch(axios.get(`${this.apiUrl}/check-publish/${id}`));
  }

  async publish(id: string) {
    return axiosCatch(axios.patch(`${this.apiUrl}/publish/${id}`));
  }

  async unpublish(id: string) {
    return axiosCatch(axios.patch(`${this.apiUrl}/unpublish/${id}`));
  }

  async delete({ id, parentId }: { id: string; parentId: string }) {
    return axiosCatch(
      axios.delete(`${this.apiUrl}/${id}`, {
        params: {
          parentId: parentId,
        },
      }),
    );
  }
}
