import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { getQueryVariable } from 'src/utils/common';

import { ROUTE_PREFIX, ROUTES } from 'src/constants/routes';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../stores';
import RedirectSourceSystemFail from './AuthFail';
import { TOOL_MODULES } from '../../constants';

interface IProps {}

const AuthPage: React.FC<IProps> = observer(() => {
  const { authStores } = useStores();
  const {
    isError,
    setAccessFilter,
    isLoggedIn,
    setIsAuthComplete,
  } = authStores;

  React.useEffect(() => {
    setIsAuthComplete(!!isLoggedIn);
  }, [isLoggedIn, setIsAuthComplete]);

  const loc = useLocation();
  const routeRequest = getQueryVariable(loc.search, 'route');
  const resetFilter = getQueryVariable(loc.search, 'resetFilter');

  const typeQuery = getQueryVariable(loc.search, 'type'); // e.g. accessScope
  const accessScopeQuery = getQueryVariable(loc.search, 'accessScope');
  const school = getQueryVariable(loc.search, 'school');
  const organization = getQueryVariable(loc.search, 'organization');
  const orgType = getQueryVariable(loc.search, 'orgType');
  const jurisdiction = getQueryVariable(loc.search, 'jurisdiction');
  const label = getQueryVariable(loc.search, 'label');

  const accessScope = accessScopeQuery || typeQuery;

  let module;

  if (routeRequest === ROUTES.MODULE_BUILD) {
    module = TOOL_MODULES.BUILD;
  }

  if (resetFilter && module && setAccessFilter) {
    setAccessFilter(module, null);
  }

  if (
    module &&
    setAccessFilter &&
    accessScope &&
    label &&
    (school || organization || orgType || jurisdiction)
  ) {
    setAccessFilter(module, {
      accessScope,
      label,
      scope: {
        organization,
        school,
        orgType,
        jurisdiction,
      },
    });
  }

  if (isError) {
    return (
      <>
        <RedirectSourceSystemFail />
      </>
    );
  }

  const to = routeRequest ? ROUTE_PREFIX + routeRequest : ROUTES.ROOT;

  return (
    <Redirect
      to={{
        pathname: to,
      }}
    />
  );
});

export default AuthPage;
