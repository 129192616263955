import React from 'react';
import { useIntl } from 'react-intl';

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { Grid, Paper, useMediaQuery, useTheme } from '@material-ui/core';

import {
  AnvBreadcrumbs,
  AnvHtml,
  AnvViewHeader,
  TextH1,
  AnvUtils,
} from '@platform/front-lib';

import { useStores } from 'src/stores';
import { ModuleContext } from 'src/pages/common/module-context';

import {
  IKnowledgeBase,
  IKnowledgeParamsCommon,
  IKnowledgeTutorial,
} from 'src/models';

import AnvTutorialCard from './card';

import { AnvChipUnpublished } from 'src/pages/common/chip-unpublished';

import { prepareBreadCrumbsItems } from 'src/utils';
import { anvilarSegment, TOOL_MODULES } from 'src/constants';

import { AnvTutorialCardCommon } from './card-common';

interface IProps {
  baseEntity: IKnowledgeBase;
  tutorials: IKnowledgeTutorial[];
  tutorial: IKnowledgeTutorial;
}

export const RootTutorials: React.FC<IProps> = observer(
  ({ baseEntity, tutorials, tutorial }) => {
    const { locale } = useIntl();

    const { baseId } = useParams<IKnowledgeParamsCommon>();

    const isAnvilarBase = anvilarSegment.indexOf(baseId) > -1;

    const module = React.useContext(ModuleContext);
    const isBuildModule = module === TOOL_MODULES.BUILD;
    const {
      authStores: { isOperatorLoggedIn },
      [isBuildModule ? 'buildStore' : 'viewStore']: {
        knowledgeBaseStore: { dataMap: knowledgeBaseMap },
        tutorialsStore: { dataMapId },
      },
    } = useStores();

    const theme = useTheme();
    const screenSM = useMediaQuery(theme.breakpoints.down('sm'));

    const breadCrumbsItems = prepareBreadCrumbsItems({
      knowledgeBaseUrlId: baseId,

      tutorialsUrlsId: '',
      knowledgeBaseMap,
      tutorialsMap: {},

      isView: !isBuildModule,
      isPreview: isBuildModule,
      locale,
    });

    const customTitle = (
      <Grid
        container
        direction={'column'}
        wrap={'nowrap'}
        alignItems={screenSM ? 'center' : 'flex-start'}
      >
        <Grid item>
          <TextH1>
            {AnvUtils.getNameByLangNullable(baseEntity, 'title', locale)}
            {!baseEntity?.published && isBuildModule && <AnvChipUnpublished />}
          </TextH1>
        </Grid>

        {isAnvilarBase && (
          <Grid item style={{ width: 'inherit' }}>
            <AnvHtml
              text={
                AnvUtils.getNameByLangNullable(
                  baseEntity,
                  'description',
                  locale,
                ) || ''
              }
            />
          </Grid>
        )}
      </Grid>
    );

    return (
      <>
        <AnvBreadcrumbs
          moduleCode={isBuildModule ? module : undefined}
          items={breadCrumbsItems}
          isOperator={isOperatorLoggedIn}
        />

        <Paper>
          <AnvViewHeader customTitle={customTitle} textAlign={'left'} />

          <Grid
            container
            spacing={screenSM ? undefined : 2}
            direction={screenSM ? 'column' : 'row'}
            style={{
              padding: screenSM ? '24px 16px' : '40px',
            }}
          >
            {tutorials.map((subTutorialItem: IKnowledgeTutorial) => {
              if (!subTutorialItem.urlSegment) {
                return null;
              }

              return isAnvilarBase || screenSM ? (
                <AnvTutorialCard
                  key={subTutorialItem._id}
                  tutorial={subTutorialItem}
                  dataMapId={dataMapId}
                />
              ) : (
                <AnvTutorialCardCommon
                  key={subTutorialItem._id}
                  tutorial={subTutorialItem}
                  dataMapId={dataMapId}
                />
              );
            })}
          </Grid>
        </Paper>
      </>
    );
  },
);

export default RootTutorials;
