import React from 'react';

import { Grid } from '@material-ui/core';

import { TextH4 } from '@platform/front-lib';

import { useStylesCardTitle } from './card-title.styles';

interface IProps {
  title: string | JSX.Element;
}

export const AnvCardTitle: React.FC<IProps> = ({ title }) => {
  const classes = useStylesCardTitle();

  return (
    <Grid
      container
      direction={'column'}
      wrap={'nowrap'}
      className={classes.wrapper}
    >
      <TextH4>{title}</TextH4>
    </Grid>
  );
};
