import { action, makeObservable } from 'mobx';
import { AnvUtils } from '@platform/front-lib';

import { ICommonService, IKnowledgeBase } from '../../../../models';
import { KnowledgeBaseService } from '../../../../service/knowledge';
import { IActionMeta } from '../../../_helpers';
import { CommonBaseStore } from '../../base-common';

const { historyPushPagination, preparePaginationFilterData } = AnvUtils;

class BuildBaseStore extends CommonBaseStore {
  baseService: KnowledgeBaseService;

  create = async (
    payload: Omit<IKnowledgeBase, 'tutorials' | '_id'>,
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.baseService.create(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.();
    });
  };

  update = async (
    payload: Omit<IKnowledgeBase, 'tutorials'>,
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.baseService.update(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    const base: IKnowledgeBase = response;

    this.setLoaded(() => {
      if (base.urlSegmentId) {
        this.dataMap[base.urlSegmentId] = {
          ...(this.dataMap[base.urlSegmentId] || {}),
          ...base,
        };
      }
      if (base._id) {
        this.dataMapId[base._id] = {
          ...(this.dataMap[base._id] || {}),
          ...base,
        };
      }
      meta?.successFunc?.();
    });
  };

  delete = async (id: string, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.baseService.delete(id);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.();
    });
  };

  publish = async (
    { id, entityKey }: { id: string; entityKey: string },
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.baseService.publish({ id });

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      if (!!entityKey && this.dataMap && this.dataMap[entityKey]) {
        this.dataMap[entityKey].published = true;
      }
      if (!!id && this.dataMapId && this.dataMapId[id]) {
        this.dataMapId[id] = {
          ...this.dataMapId[id],
          ...response,
        };
      }

      if (!entityKey && !!this.data) {
        this.data = this.data.map((item) => {
          if (item._id === id) {
            return {
              ...item,
              published: true,
            };
          }
          return item;
        });
      }

      meta?.successFunc?.();
    });
  };

  unpublish = async (
    { id, entityKey }: { id: string; entityKey: string },
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.baseService.unpublish({ id });

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      if (!!entityKey && this.dataMap && this.dataMap[entityKey]) {
        this.dataMap[entityKey].published = false;
      }

      if (!entityKey && !!this.data) {
        this.data = this.data.map((item) => {
          if (item._id === id) {
            return {
              ...item,
              published: false,
            };
          }
          return item;
        });
      }

      meta?.successFunc?.();
    });
  };

  setPage = (page: number, { history }: Record<string, any>) => {
    if (history) {
      historyPushPagination({ payload: { page }, history });
    }

    this.pagination.page = page;
  };

  setSort = (sort: string, { history }: Record<string, any>) => {
    if (history) {
      historyPushPagination({ payload: { sort }, history });
    }

    this.sort = sort;
  };

  setFilter = (
    filter: any[] | Record<string, any>,
    { history, ignorePage }: Record<string, any>,
  ) => {
    let filterParam = preparePaginationFilterData(filter, { ignorePage });
    if (history) {
      historyPushPagination({
        payload: filterParam,
        history,
        isFilters: true,
      });
    }

    this.filter = filter;
  };

  constructor({ isView }: ICommonService) {
    super({ isView });
    this.baseService = new KnowledgeBaseService({ isView });

    makeObservable(this, {
      create: action,
      update: action,
      delete: action,
      publish: action,
      unpublish: action,

      setPage: action,
      setFilter: action,
      setSort: action,
    });
  }
}

export default new BuildBaseStore({ isView: false });
