import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { AnvHtml, AnvIcon, AnvUtils } from '@platform/front-lib';

import { IKnowledgeTutorial } from '../../../../../models';
import { ModuleContext } from '../../../../common/module-context';

import { AnvChipUnpublished } from '../../../../common/chip-unpublished';

import { TOOL_MODULES } from '../../../../../constants';
import { useStyles } from './styles';

interface IProps {
  tutorial: IKnowledgeTutorial;
}

export const ViewTutorial: React.FC<IProps> = ({ tutorial }) => {
  const { locale } = useIntl();
  const classes = useStyles();

  const { url } = useRouteMatch();

  const module = React.useContext(ModuleContext);
  const isBuildModule = module === TOOL_MODULES.BUILD;

  const link = (urlSegment?: string) => {
    if (!!urlSegment) {
      return `${url}/${urlSegment}`;
    }

    return `${url}`;
  };

  return (
    <Paper className={classes.paperRoot}>
      <Grid container>
        <Grid
          container
          item
          justify={'space-between'}
          direction="row"
          wrap={'nowrap'}
          className={classes.titleWrapper}
        >
          <Grid
            container
            justify={'flex-start'}
            direction="row"
            wrap={'nowrap'}
            alignItems="center"
          >
            {tutorial?.icon && (
              <span
                style={{
                  marginRight: '12px',
                }}
              >
                <AnvIcon icon={tutorial?.icon} size={24} />
              </span>
            )}

            <p className={classes.tutorialTitle}>
              {AnvUtils.getNameByLangNullable(tutorial, 'title', locale)}

              {!tutorial?.published && isBuildModule && <AnvChipUnpublished />}
            </p>
          </Grid>

          <Grid container justify="flex-end">
            <NavLink to={link(tutorial.urlSegment)} className={classes.link}>
              <AnvIcon
                icon={'bookOpen'}
                size={16}
                color={'primary'}
                style={{ marginRight: 8 }}
              />
              <FormattedMessage
                id="KNOWLEDGE_BASE.VIEW.TUTORIAL.LINK.TEXT"
                defaultMessage="Tutorial"
              />
            </NavLink>
          </Grid>
        </Grid>

        <Grid>
          <AnvHtml
            text={AnvUtils.getNameByLangNullable(
              tutorial,
              'description',
              locale,
            )}
            className={classes.descriptionWrapper}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ViewTutorial;
