import { axiosInstance } from '@platform/front-lib';

import { axiosCatch } from 'src/service/utils';

import { IGetList, IPayloadItemById } from '../../../service/interfaces';
import { IExplorerAddToList } from '../../common/list-store';

const axios = axiosInstance;

export interface IGetAllChain {
  rootId: string;
  folders?: string[];
}

export class BuildService {
  private readonly apiUrl: string;

  constructor({ isView }: { isView: boolean }) {
    this.apiUrl = isView
      ? process.env.REACT_APP_API_TOOL_ENDPOINT + '/view'
      : process.env.REACT_APP_API_TOOL_ENDPOINT + '/build';
  }

  async addToList(data?: IExplorerAddToList) {
    return axiosCatch(axios.post(`${this.apiUrl}/add-to-list`, data, {}));
  }

  async getList(data: IGetList) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/list`, {
        params: data,
      }),
    );
  }

  async createNewRootBase(data: IPayloadItemById) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/new/${data.id}`, {
        params: data,
      }),
    );
  }

  async getOne(payload: IPayloadItemById) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/root-folders/${payload.id}`, {}),
    );
  }
}
