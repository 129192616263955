import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    paperRoot: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },

    unpublishedChip: {
      marginLeft: theme.spacing(1),
      height: 18,
    },

    titleWrapper: {
      marginBottom: theme.spacing(2),
    },
    descriptionWrapper: {
      '& > p': {
        margin: 0,
      },
    },
    tutorialTitle: {
      margin: 0,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.18px',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '14px',
      color: theme.palette.brand.main,
      textDecoration: 'none',
    },
  }),
);
