import React from 'react';
import { useTheme, Theme } from '@material-ui/core';
import { AnvForbiddenPage } from '@platform/front-lib';
import { observer } from 'mobx-react-lite';

import ContainerAuth from '../components/layout/container-auth';
import { useStores } from '../stores';
import { TOOL_CODE } from '../constants';

const ForbiddenPage = observer(() => {
  const {
    palette: { brand },
  } = useTheme<Theme>();

  const {
    authStores: { isOperatorLoggedIn },
  } = useStores();

  return (
    <ContainerAuth>
      <AnvForbiddenPage
        shankColor={brand.main}
        lockBodyColor={brand.light}
        numbersColor={brand.main}
        toolCode={TOOL_CODE}
        isOperator={isOperatorLoggedIn}
      />
    </ContainerAuth>
  );
});

export default ForbiddenPage;
