import React from 'react';
import { Chip } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './styles';

interface IChipUnpublishedProps {
  short?: boolean;
}

export const AnvChipUnpublished: React.FC<IChipUnpublishedProps> = ({
  short,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Chip
      className={classes.unpublishedChip}
      size="small"
      label={
        short ? (
          <FormattedMessage
            id="KNOWLEDGE_BASE.BUILD.CHIP.UNPUBLISHED.SHORT"
            defaultMessage="U"
          />
        ) : (
          <FormattedMessage
            id="KNOWLEDGE_BASE.BUILD.CHIP.UNPUBLISHED"
            defaultMessage="Unpublished"
          />
        )
      }
      title={formatMessage({
        id: 'KNOWLEDGE_BASE.BUILD.CHIP.UNPUBLISHED',
        defaultMessage: 'Unpublished',
      })}
    />
  );
};
