import { action, makeObservable, observable } from 'mobx';

import { IKnowledgeTutorial } from '../../models';
import { IActionMeta } from '../_helpers';
import { TutorialsService } from '../../service/knowledge';
import { IGetList } from '../../service/interfaces';

interface IGetListChain extends IGetList {
  tutorialsSegments: string[];
  baseId: string;
}

export interface IBaseAndTutorialsCommonPayload {
  tutorialId: string;
  baseId: string;
}

export interface IGetTutorials extends IGetList {
  parentId?: string;
  baseId: string;

  withChildren?: boolean;
  root?: boolean;
}

export class CommonTutorialsStore {
  tutorialService: TutorialsService;

  error: Record<string, any> | null | string = null;

  dataMap: Record<string, IKnowledgeTutorial> = {};

  dataMapId: Record<string, IKnowledgeTutorial> = {};
  dataMapIdLink: Record<string, string> = {};

  data: IKnowledgeTutorial[] | null = null;
  dataRoot: IKnowledgeTutorial[] | null = null;

  publishEnable: boolean = false;

  isFetching: boolean = false;
  isSaving: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  resetErrors = () => {
    this.error = null;
    this.isError = false;
  };

  getOne = async (
    payload: IBaseAndTutorialsCommonPayload,
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.tutorialService.getOne(
      payload.baseId,
      payload.tutorialId,
    );

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.dataMapId[response._id] = response;

      this.generateMap({
        tutorials: [response],
        baseSegment: payload.baseId,
      });
    });
  };

  getTutorialsChain = async (payload: IGetListChain) => {
    this.setLoading();
    const [error, response] = await this.tutorialService.getAllChain(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      const tutorials = (response.knowledgeTutorials ||
        []) as IKnowledgeTutorial[];

      tutorials?.forEach?.((item) => {
        this.dataMapId[item?._id || ''] = item;
      });

      this.generateMap({
        tutorials: response.knowledgeTutorials,
        baseSegment: payload.baseId,
      });
    });
  };

  clearErrors = () => {
    this.error = null;
    this.isError = false;
  };

  generateMap = async ({
    tutorials,
    baseSegment,
  }: {
    tutorials: IKnowledgeTutorial[];
    baseSegment: string;
  }) => {
    const baseKey = `${baseSegment}/`;

    tutorials?.forEach((tutorial) => {
      const tutorialKey = this.generateKey(tutorial);

      if (!(tutorial && tutorialKey)) {
        return;
      }

      const key = `${baseKey}${tutorialKey}`;

      this.dataMap[key] = tutorial;
      if (tutorial._id) {
        this.dataMapIdLink[tutorial._id] = key;
      }
    });
  };

  private generateKey = (tutorial: IKnowledgeTutorial): string => {
    if (!tutorial) {
      return '';
    }

    let key = tutorial.urlSegment || '';

    if (tutorial?.parentRef?._id) {
      const parentEntity: IKnowledgeTutorial = this.dataMapId?.[
        tutorial?.parentRef._id
      ];

      if (parentEntity) {
        const subKey = this.generateKey(parentEntity);

        if (subKey) {
          key = `${subKey}/${key}`;
        }
      }
    }

    return key;
  };

  getTutorials = async (payload: IGetTutorials) => {
    this.setLoading();

    const [error, response] = await this.tutorialService.getAll(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      const tutorials = (response.knowledgeTutorials ||
        []) as IKnowledgeTutorial[];

      if (payload?.root) {
        this.dataRoot = tutorials;
      } else {
        this.data = tutorials;
      }

      tutorials?.forEach?.((item) => {
        this.dataMapId[item?._id || ''] = item;
      });

      this.generateMap({
        tutorials: response.knowledgeTutorials,
        baseSegment: payload.baseId,
      });

      if (payload.withChildren) {
        const childrenTutorials = (response.childrenTutorials ||
          []) as IKnowledgeTutorial[];

        childrenTutorials?.forEach?.((item) => {
          this.dataMapId[item?._id || ''] = item;
        });

        this.generateMap({
          tutorials: childrenTutorials,
          baseSegment: payload.baseId,
        });
      }
    });
  };

  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };
  setLoaded = (func?: () => void) => {
    func?.();

    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;
  };

  reset = () => {
    this.data = null;
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  };

  constructor({ isView }: { isView: boolean }) {
    this.tutorialService = new TutorialsService({ isView });

    makeObservable(this, {
      error: observable,
      dataMap: observable,
      dataMapId: observable,
      data: observable,
      publishEnable: observable,
      isFetching: observable,
      isSaving: observable,
      isLoaded: observable,
      isError: observable,

      setLoading: action,
      setError: action,
      setLoaded: action,
      reset: action,
      clearErrors: action,

      getOne: action,
      getTutorialsChain: action,
      getTutorials: action,
      generateMap: action,
      resetErrors: action,
      // getAllByBase: action,
    });
  }
}
