import React from 'react';
import { useIntl } from 'react-intl';

import { NavLink, useRouteMatch } from 'react-router-dom';
import { Collapse, Grid, IconButton, useTheme } from '@material-ui/core';

import { AnvIcon, AnvUtils, TextH4 } from '@platform/front-lib';

import { IKnowledgeTutorial } from '../../../../../../models';

import { AnvChipUnpublished } from '../../../../../common/chip-unpublished';
import { AnvCardContent } from '../content';

import { useStylesRootCardCommon } from './styles';
import clsx from 'clsx';

interface IProps {
  tutorial: IKnowledgeTutorial;
  dataMapId: Record<string, IKnowledgeTutorial>;
}

export const AnvTutorialCardCommon: React.FC<IProps> = ({
  dataMapId,
  tutorial,
}) => {
  const theme = useTheme();
  const { locale } = useIntl();

  const { theme: tutorialTheme, icon: tutorialIcon } = tutorial;

  const toolTheme = {
    toolColors: tutorialTheme,
    theme: theme,
  };

  const classes = useStylesRootCardCommon(toolTheme);

  const { url } = useRouteMatch();

  const [collapsed, setCollapsed] = React.useState(false);
  const toggleCollapse = () => {
    setCollapsed((state) => !state);
  };

  if (!tutorial.urlSegment) {
    return null;
  }

  const handlePreventDefault = (ev: any) => {
    // ev?.preventDefault();
    ev?.stopPropagation();
  };

  const subTutorials = tutorial.tutorials
    ?.map((tutorialId) => {
      return dataMapId[tutorialId];
    })
    .filter((item) => !!item);
  const isSubTutorialsExists = !!subTutorials?.length;

  return (
    <Grid item xs={12} className={classes.root}>
      <Grid container wrap={'nowrap'}>
        <Grid item className={classes.leftBlock}>
          <div className={classes.iconWrapper}>
            <AnvIcon icon={tutorialIcon || ''} size={24} />
          </div>
        </Grid>

        <Grid
          item
          className={clsx(classes.contentWrapper, {
            [classes.contentWrapperCollapsible]: isSubTutorialsExists,
          })}
          onClick={toggleCollapse}
        >
          <NavLink
            to={AnvUtils.cleanUrl(`${url}/${tutorial.urlSegment}`)}
            className={classes.link}
            onClick={handlePreventDefault}
          >
            <TextH4 className={classes.linkText}>
              {AnvUtils.getNameByLangNullable(tutorial, 'title', locale)}
              {!tutorial.published && <AnvChipUnpublished short />}
              <span className={classes.linkChevron}>
                <AnvIcon icon={'chevronRight'} />
              </span>
            </TextH4>
          </NavLink>

          {isSubTutorialsExists && (
            <>
              <IconButton className={classes.collapseButton} size={'small'}>
                <AnvIcon icon={'chevronDown'} rotate={collapsed ? 180 : 0} />
              </IconButton>

              <Collapse in={collapsed}>
                <AnvCardContent
                  isCommon
                  parentSlug={tutorial.urlSegment}
                  subTutorials={subTutorials}
                />
              </Collapse>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
