import React from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import { IsLoadingComponent } from '@platform/front-lib';
import Container from './components/layout/container';
import { NotFoundBase } from './pages/view/common/not-found-base';

import PrivateRoute from './route-handlers/PrivateRoute';
import ForbiddenPage from './route-handlers/ForbiddenPage';

import { TOOL_MODULES, ROUTES } from './constants';
import NotFoundPage from './route-handlers/NotFoundPage';
import AuthPage from './pages/auth';

import RedirectSourceSystemFail from './pages/auth/AuthFail';

// import KnowledgeBaseForm from './pages/build/knowledge-base/form';
// import KnowledgeBase from './pages/build/knowledge-base';

// import KnowledgeTutorial from './pages/build/knowledge-tutorials';
// import KnowledgeTutorialForm from './pages/build/knowledge-tutorials/form';
// import KnowledgeChapters from './pages/build/knowledge-chapters';
// import KnowledgeChaptersForm from './pages/build/knowledge-chapters/form';

// deprecated till subscriptions
// import ViewKnowledgeBase from './pages/view/knowledge-base';

// import ViewKnowledgeChapters from './pages/view/knowledge-chapters';
import ViewKnowledgeTutorial from './pages/view/knowledge-tutorials';
import { KnowledgeContainer } from './pages/view/common/container-store';
import { BuildRoot } from './pages/build/root';
import { PageBuildList } from './pages/build/list';
import { PageViewList } from './pages/view/list';
import { PageBuildNewBase } from './pages/build/new';
import { RelatedLinkHandler } from './route-handlers/related-link-handler';

const KnowledgeBaseForm = React.lazy(
  () => import('./pages/build/knowledge-base/form'),
);
const KnowledgeBase = React.lazy(() => import('./pages/build/knowledge-base'));

const KnowledgeTutorial = React.lazy(
  () => import('./pages/build/knowledge-tutorials'),
);
const KnowledgeTutorialForm = React.lazy(
  () => import('./pages/build/knowledge-tutorials/form'),
);
const KnowledgeChapters = React.lazy(
  () => import('./pages/build/knowledge-chapters'),
);
const KnowledgeChaptersForm = React.lazy(
  () => import('./pages/build/knowledge-chapters/form'),
);
interface IProps {}

const Routes: React.FC<IProps> = () => {
  return (
    <BrowserRouter>
      <Container>
        <React.Suspense fallback={<IsLoadingComponent isLoading />}>
          <Switch>
            {/* Build */}
            <Redirect to={ROUTES.MODULE_BUILD_ROOT} from={'/'} exact />
            {/*<Redirect to={ROUTES.KNOWLEDGE_BASE_LIST} from={'/build'} exact />*/}
            <PrivateRoute
              exact
              path={ROUTES.MODULE_BUILD_NEW}
              component={PageBuildNewBase}
              module={TOOL_MODULES.BUILD}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODULE_BUILD}
              component={BuildRoot}
              module={TOOL_MODULES.BUILD}
              // Container={KnowledgeContainer}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODULE_BUILD_USER_LIST}
              component={PageBuildList}
              module={TOOL_MODULES.BUILD}
              // Container={KnowledgeContainer}
            />
            <PrivateRoute
              exact
              path={ROUTES.KNOWLEDGE_BUILD_PREVIEW}
              component={ViewKnowledgeTutorial}
              module={TOOL_MODULES.BUILD}
              Container={KnowledgeContainer}
            />
            <PrivateRoute
              exact
              path={ROUTES.KNOWLEDGE_BASE_FORM}
              component={KnowledgeBaseForm}
              module={TOOL_MODULES.BUILD}
            />
            <PrivateRoute
              exact
              path={ROUTES.KNOWLEDGE_BASE_LIST}
              component={KnowledgeBase}
              module={TOOL_MODULES.BUILD}
            />
            <PrivateRoute
              exact
              path={ROUTES.KNOWLEDGE_CHAPTER_FORM}
              component={KnowledgeChaptersForm}
              module={TOOL_MODULES.BUILD}
            />
            <PrivateRoute
              exact
              path={ROUTES.KNOWLEDGE_CHAPTER_LIST}
              component={KnowledgeChapters}
              module={TOOL_MODULES.BUILD}
            />
            <PrivateRoute
              exact
              path={ROUTES.KNOWLEDGE_TUTORIAL_FORM}
              component={KnowledgeTutorialForm}
              module={TOOL_MODULES.BUILD}
            />
            <PrivateRoute
              exact
              path={ROUTES.KNOWLEDGE_TUTORIAL_LIST}
              component={KnowledgeTutorial}
              module={TOOL_MODULES.BUILD}
              Container={KnowledgeContainer}
            />
            <PrivateRoute
              exact
              path={ROUTES.RELATED_LINK_DISPATCHER}
              component={RelatedLinkHandler}
            />
            {/* Access */}
            <Route exact path={ROUTES.USER_AUTH} component={AuthPage} />
            <Route
              exact
              path={ROUTES.AUTH_FORBIDDEN}
              component={ForbiddenPage}
            />
            <Route
              exact
              path={ROUTES.AUTH_FAIL}
              component={RedirectSourceSystemFail}
            />
            {/* View */}
            <Redirect
              from={ROUTES.MODULE_VIEW}
              to={ROUTES.VIEW_KNOWLEDGE_BASE_LIST}
              exact
            />
            <PrivateRoute
              exact
              path={ROUTES.VIEW_KNOWLEDGE_BASE_NOT_FOUND}
              component={NotFoundBase}
              module={TOOL_MODULES.VIEW}
            />
            <PrivateRoute
              path={ROUTES.VIEW_KNOWLEDGE_BASE_LIST}
              component={PageViewList}
              module={TOOL_MODULES.VIEW}
            />

            <Route
              path={ROUTES.VIEW_KNOWLEDGE_TUTORIAL_LIST}
              component={ViewKnowledgeTutorial}
              module={TOOL_MODULES.VIEW}
              Container={KnowledgeContainer}
            />
            <Route
              exact
              path={ROUTES.VIEW_KNOWLEDGE_TUTORIAL_LIST_ROOT}
              component={ViewKnowledgeTutorial}
              module={TOOL_MODULES.VIEW}
              Container={KnowledgeContainer}
            />

            {/* deprecated till subscriptions */}
            {/*<PrivateRoute*/}
            {/*  exact*/}
            {/*  path={ROUTES.VIEW_KNOWLEDGE_CHAPTER_LIST}*/}
            {/*  component={ViewKnowledgeChapters}*/}
            {/*  module={TOOL_MODULES.VIEW}*/}
            {/*/>*/}
            <Redirect from="//*" to="/*" />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </React.Suspense>
      </Container>
    </BrowserRouter>
  );
};

export default Routes;
