import React from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';

import { Paper } from '@material-ui/core';

import { AnvBreadcrumbs } from '@platform/front-lib';

import { IKnowledgeParamsCommon } from 'src/models';

import { useStores } from 'src/stores';
import { ModuleContext } from 'src/pages/common/module-context';

import PrivateRoute from 'src/route-handlers/PrivateRoute';

import RootTutorials from '../common/view-tutorials/root-tutorial';
import { ViewTutorial } from '../common/view-tutorials/tutorial';
import ViewTutorialTitle from '../common/view-tutorials/tutorial-title';
import AnvChapters from '../common/view-tutorials/chapters';

import { prepareBreadCrumbsItems, urlReplacer } from 'src/utils';
import { TOOL_MODULES } from 'src/constants';

import { useStyles } from './styles';
import { useIntl } from 'react-intl';
import { KnowledgeContainer } from 'src/pages/view/common/container-store';

interface IProps {}

const ViewKnowledgeTutorial: React.FC<IProps> = observer(() => {
  const { locale } = useIntl();
  const { url } = useRouteMatch();

  const { baseId, tutorialsId } = useParams<IKnowledgeParamsCommon>();
  const classes = useStyles();

  const isRootTutorialScreen = !tutorialsId;

  const [restoreData, setRestoreData] = React.useState(false);

  React.useEffect(() => {
    if (restoreData) {
      setRestoreData(false);
    }
  }, [restoreData]);

  const {
    authStores: { isOperatorLoggedIn },
    buildStore,
    viewStore,
  } = useStores();

  const module = React.useContext(ModuleContext);
  const isBuildModule = module === TOOL_MODULES.BUILD;
  const moduleStore = isBuildModule ? buildStore : viewStore;

  const {
    knowledgeBaseStore: { dataMap: knowledgeBaseMap },
    tutorialsStore: { data: tutorials, dataMap: tutorialsMap, getTutorials },
    chaptersStore: { data: chapters, getEntities: getChapters },
  } = moduleStore;

  const parentId = `${baseId}${
    tutorialsId
      ? `/${urlReplacer({ url: tutorialsId, remove: '/chapters' })}`
      : ''
  }`;

  const parentEntity =
    knowledgeBaseMap?.[parentId] || tutorialsMap?.[parentId] || '';

  const baseEntity = knowledgeBaseMap?.[`${baseId}`];

  React.useEffect(() => {
    if (parentEntity?._id || !tutorialsId) {
      const parentTutorialId = tutorialsId && parentEntity._id;

      getTutorials({
        parentId: parentTutorialId,
        baseId,

        withChildren: isRootTutorialScreen,
      });
    }
  }, [
    baseId,
    isRootTutorialScreen,
    parentEntity._id,
    tutorialsId,
    getTutorials,
  ]);

  React.useEffect(() => {
    if (
      (!isRootTutorialScreen && parentEntity._id) ||
      (!isBuildModule && !tutorialsId)
    ) {
      getChapters({
        id: baseId,
        parentId: parentEntity?._id || '',
      });
    }
  }, [
    isBuildModule,
    baseId,
    isRootTutorialScreen,
    parentEntity._id,
    tutorialsId,
    getChapters,
  ]);

  const breadCrumbsItems = prepareBreadCrumbsItems({
    knowledgeBaseUrlId: baseId,

    tutorialsUrlsId: tutorialsId,
    knowledgeBaseMap,
    tutorialsMap,

    isView: !isBuildModule,
    isPreview: isBuildModule,
    locale,
  });

  return (
    <KnowledgeContainer>
      {isRootTutorialScreen ? (
        tutorials &&
        baseEntity && (
          <RootTutorials
            baseEntity={baseEntity}
            tutorial={parentEntity}
            tutorials={tutorials}
          />
        )
      ) : (
        <>
          <AnvBreadcrumbs
            moduleCode={isBuildModule ? module : undefined}
            // tool={tool}
            isOperator={isOperatorLoggedIn}
            items={breadCrumbsItems}
          />

          <ViewTutorialTitle tutorial={parentEntity} />

          {tutorials?.map((tutorial) => {
            return <ViewTutorial key={tutorial._id} tutorial={tutorial} />;
          })}

          {!!chapters?.length && (
            <Paper className={classes.chaptersWrapper}>
              <AnvChapters chapters={chapters} />
            </Paper>
          )}
        </>
      )}

      <Switch>
        <PrivateRoute
          exact
          path={`${url}/:id`}
          component={<ViewKnowledgeTutorial />}
          module={module}
        />
      </Switch>
    </KnowledgeContainer>
  );
});

export default ViewKnowledgeTutorial;
