import React from 'react';
import { Grid, Paper, useMediaQuery, useTheme } from '@material-ui/core';

import { AnvHtml, AnvIcon, AnvUtils, TextH3 } from '@platform/front-lib';

import { IKnowledgeTutorial } from 'src/models';

import { ModuleContext } from 'src/pages/common/module-context';
import { AnvChipUnpublished } from 'src/pages/common/chip-unpublished';

import { TOOL_MODULES } from 'src/constants';

import { useStyles } from './styles';
import { useIntl } from 'react-intl';

interface IProps {
  tutorial: IKnowledgeTutorial;
}

const ViewTutorialTitle: React.FC<IProps> = ({ tutorial }) => {
  const { locale } = useIntl();

  const tutorialTheme = tutorial.theme;
  const theme = useTheme();
  const classes = useStyles({ tutorialTheme, theme });

  const module = React.useContext(ModuleContext);
  const isBuildModule = module === TOOL_MODULES.BUILD;

  const screenSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper elevation={8} className={classes.header}>
      <Grid
        container
        direction={screenSM ? 'column' : 'row'}
        alignItems={screenSM ? 'center' : 'flex-start'}
        wrap={'nowrap'}
      >
        <Grid>
          {/*{icon && <div className={classes.iconWrapper}>{icon}</div>}*/}
          {tutorial?.icon && (
            <div className={classes.iconWrapper}>
              <AnvIcon icon={tutorial?.icon} size={24} />
            </div>
          )}
        </Grid>

        <Grid
          container
          alignItems={screenSM ? 'center' : 'flex-start'}
          direction={'column'}
        >
          <Grid style={{ margin: '14px 0px 12px 0px' }}>
            <TextH3>
              {AnvUtils.getNameByLangNullable(tutorial, 'title', locale)}

              {!tutorial?.published && isBuildModule && <AnvChipUnpublished />}
            </TextH3>
          </Grid>
          <AnvHtml
            text={AnvUtils.getNameByLangNullable(
              tutorial,
              'description',
              locale,
            )}
            className={classes.descriptionWrapper}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ViewTutorialTitle;
