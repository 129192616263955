import { makeObservable } from 'mobx';
import { CommonChaptersStore } from '../../chapters-common';
import { ICommonService } from '../../../../models/common';

class ViewChaptersStore extends CommonChaptersStore {
  constructor({ isView }: ICommonService) {
    super({ isView });

    makeObservable(this, {});
  }
}

export default new ViewChaptersStore({ isView: true });
