// extend palette https://medium.com/javascript-in-plain-english/extend-material-ui-theme-in-typescript-a462e207131f
export const palette = {
  primary: {
    main: '#174D51',
  },
  error: {
    main: '#F44D4D',
  },
  brand: {
    main: '#3F51B5',
    light: '#E2E5F4',
    // dark: '#6A3822',
    dark: '#3F51B5',
    // veryDark: '#692532',
  },
  common: {
    white: '#FFFFFF',
  },
  custom: {
    lightIcons: '#9DADAF',
    disabled: '#818181',
    textLight: '#8C8C8C',
    background: '#F2F5F5',
    controlBorder: '#CBD1DA',
    divider: '#D9E1E2',
    blueContrast: '#0065FF',
  },
  background: {
    default: '#F2F5F5',
    paper: '#FFFFFF',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.88)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    body: 'rgba(0, 0, 0, 0.7)',
    contrast: '#333333',
    disabled: 'rgba(0, 0, 0, 0.45)',
    hyperlink: '#18A0FB',
    redcolor: '#ff1744',
    white: '#FFFFFF',
    lightIcons: '#9DADAF',
  },
  border: {
    primary: '#CCCCCC',
  },
};
