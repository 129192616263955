import { makeObservable } from 'mobx';
import { CommonTutorialsStore } from '../../tutorials-common';
import { TutorialsService } from '../../../../service/knowledge';
import { ICommonService } from '../../../../models/common';

class ViewTutorialsStore extends CommonTutorialsStore {
  tutorialService: TutorialsService;

  constructor({ isView }: ICommonService) {
    super({ isView });
    this.tutorialService = new TutorialsService({ isView });

    makeObservable(this, {});
  }
}

export default new ViewTutorialsStore({ isView: true });
