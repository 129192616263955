import React from 'react';
import clsx from 'clsx';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { Hidden, useTheme } from '@material-ui/core';
import DrawerFooter from '../../../components/layout/drawer/DrawerFooter';
import { useStyles } from '../../../components/layout/container/styles';
import { AnvDrawerBaseListItem } from './AnvDrawerBaseListItem';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';
import { IBaseDrawerItem } from './interfaces';

interface IAnvDrawerOperator {
  drawerData: IBaseDrawerItem[];
}

export const AnvDrawerBase: React.FC<IAnvDrawerOperator> = observer(
  ({ drawerData }) => {
    const {
      authStores: { profile, logOut },
      uiStores: { drawerOpen, toggleDrawerOpen },
    } = useStores();

    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useStyles();

    if (!drawerData) {
      return null;
    }
    const drawerContent = (
      <>
        <div className={classes.drawerContent}>
          <List className={classes.drawerList} component={'div'}>
            {drawerData &&
              drawerData.map((item) => {
                if (!item) {
                  return null;
                }

                return (
                  <AnvDrawerBaseListItem
                    screenMD={screenMD}
                    key={item.key}
                    drawerOpen={drawerOpen}
                    item={item}
                    root
                    handleDrawerToggle={toggleDrawerOpen}
                  />
                );
              })}
          </List>
        </div>
      </>
    );

    return screenMD ? (
      <Drawer
        role="navigation"
        anchor={'left'}
        open={drawerOpen}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        {drawerContent}
      </Drawer>
    ) : (
      <Drawer
        role="navigation"
        anchor={'right'}
        open={drawerOpen}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        {drawerContent}

        <Hidden mdUp>
          <DrawerFooter profile={profile} logOut={logOut} />
        </Hidden>
      </Drawer>
    );
  },
);
