import axios from 'axios';

interface IDownloadFile {
  url: string;
  filename: string;
  token?: string;
}

export const downloadFile = ({
  url,
  filename,
  token = '',
}: IDownloadFile) => () => {
  if (!filename) {
    return;
  }
  const options = {
    responseType: 'blob',
    headers: {
      Authorization: token,
    },
  };
  axios
    // @ts-ignore
    .get(url, options)
    .then(function (response) {
      const reader = new window.FileReader();
      reader.readAsDataURL(response.data);
      reader.onload = function () {
        const fileDataUrl = reader.result;

        const downloadLink = document.createElement('a');
        const fileName = filename;

        // @ts-ignore
        downloadLink.href = fileDataUrl;
        downloadLink.download = fileName;
        downloadLink.click();
      };
    })
    .catch((err) => {
      console.log({ err });
    });
};
