import KnowledgeBaseStore from './knowledge-base';
import TutorialsStore from './tutorials';
import ChaptersStore from './chapters';
import { RootCommonStore } from '../build/root-common-store';

export const ViewStore = {
  knowledgeBaseStore: KnowledgeBaseStore,
  tutorialsStore: TutorialsStore,
  chaptersStore: ChaptersStore,
  rootStore: new RootCommonStore({ isView: true }),
};
