import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStylesCardTitle = makeStyles((theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(0, 3),
      marginBottom: theme.spacing(1.5),

      color: theme.palette.primary.main,
    },
  }),
);
