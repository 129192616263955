import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { useLocation } from 'react-router';

import { Tooltip } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';

import { AnvIcon, icons } from '@platform/front-lib';

import { IBaseDrawerItem } from './interfaces';

import { useStyles } from 'src/components/layout/container/styles';

interface IAnvDrawerBaseListItem {
  item: IBaseDrawerItem;
  drawerOpen?: boolean;
  screenMD?: boolean;
  root?: boolean;
  disabled?: boolean;
  rootActive?: boolean;
  handleDrawerToggle: () => void;

  // progress?: any;
  // progressLoaded?: boolean;
}

export const AnvDrawerBaseListItem: React.FC<IAnvDrawerBaseListItem> = ({
  item,
  drawerOpen = false,
  screenMD = false,
  handleDrawerToggle,
  root = false,
  rootActive = false,
  disabled,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  let isMatch = pathname.includes(item?.to);

  const [openMenu, setOpenMenu] = React.useState(isMatch);
  const isSubmenuExists = !!item?.items?.length;
  const isOneItemInSubMenuExists = item?.items?.length === 1;
  let rootProps: Record<string, any> = {};

  React.useEffect(() => {
    if (!drawerOpen && screenMD) {
      setOpenMenu(false);
    }
  }, [drawerOpen, screenMD]);

  const handleClickMenu = () => {
    setOpenMenu((state) => !state);
  };

  const toggleProps = (item: any, isSubmenuExists?: boolean) => {
    if (isSubmenuExists) {
      item?.items?.forEach((item: any) => {
        if (item?.list) {
          rootProps.onClick = () => {
            handleDrawerToggle();
            handleClickMenu();
          };
          toggleProps(item);
        }
        toggleProps(item);
      });
    } else {
      rootProps.to = item.to.startsWith('/') ? item.to : '/' + item.to;
      rootProps.disabled = item.disabled || false;
      rootProps.component = NavLink;
    }
  };

  if (drawerOpen) {
    if (isSubmenuExists) {
      rootProps.onClick = handleClickMenu;
    } else {
      toggleProps(item);
      if (!screenMD) {
        toggleProps(item);
        rootProps.onClick = handleDrawerToggle;
      }
    }
  }
  if (!drawerOpen) {
    if (isSubmenuExists) {
      toggleProps(item, isSubmenuExists);
    } else {
      toggleProps(item);
    }
    if (isOneItemInSubMenuExists) {
      toggleProps(item, isOneItemInSubMenuExists);
    }
  }

  const icon = (
    item: Record<string, any>,
    label: JSX.Element | string,
    icons: Record<string, any>,
    drawerOpen: boolean,
  ) => {
    if (item?.icon) {
      if (drawerOpen) {
        return <AnvIcon icon={item.icon} color={'inherit'} />;
      } else {
        return (
          <Tooltip title={label} placement="right" arrow>
            <span>
              <AnvIcon icon={item.icon} color={'inherit'} />
            </span>
          </Tooltip>
        );
      }
    }
  };

  let label: string = item.name;

  const isDisabled = disabled;

  const iconStyle = root && item.iconColor ? { color: item.iconColor } : {};

  return (
    <>
      <ListItem
        button
        {...rootProps}
        disabled={isDisabled}
        className={clsx(classes.listItem, {
          [classes.itemSingleActive]: isMatch && !isSubmenuExists && root,
          [classes.itemRoot]: isSubmenuExists && root,
          [classes.itemRootOpen]: openMenu && isSubmenuExists && root,
          [classes.itemRootActive]: isMatch && isSubmenuExists && root,
          [classes.subItemActive]: isMatch && !isSubmenuExists && !root,
          [classes.subItem]: !root,
          [classes.itemRootClose]: !drawerOpen,
        })}
      >
        <ListItemIcon
          className={clsx(classes.listIcon, {
            [classes.listIconActive]: isMatch,
          })}
          style={iconStyle}
        >
          {icon(item, label, icons, drawerOpen)}
        </ListItemIcon>

        {drawerOpen && (
          <ListItemText
            disableTypography
            className={clsx({
              [classes.itemText]: drawerOpen,
              [classes.itemTextBold]: root,
              [classes.itemTextSubList]: !root,
              [classes.itemTextSubListActive]: !root && isMatch,
            })}
          >
            {label}
          </ListItemText>
        )}

        {drawerOpen ? (
          isSubmenuExists ? (
            openMenu ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : null
        ) : null}
      </ListItem>

      {drawerOpen && item.items && (
        <Collapse in={openMenu} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            className={clsx(classes.subList, {
              [classes.subListActive]: isMatch,
            })}
          >
            {item.items.map((subItem) => {
              if (!subItem) {
                return null;
              }

              return (
                <AnvDrawerBaseListItem
                  disabled={isDisabled}
                  drawerOpen={drawerOpen}
                  handleDrawerToggle={handleDrawerToggle}
                  key={subItem.key}
                  item={subItem}
                  rootActive={isMatch}
                  screenMD={screenMD}
                />
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
};
