import { createStyles, makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import mixins from 'src/styles/mixins';
import {
  APP_BAR_HEIGHT,
  drawerHeaderHeight,
  drawerWidth,
  drawerWidthDesktopClose,
  FOOTER_HEIGHT,
} from '../../../styles/constants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontFamily: 'Inter',

      maxWidth: '100vw',
      overflow: 'hidden',

      [theme.breakpoints.up('md')]: {
        display: 'flex',
        maxWidth: '100vw',
      },
    },
    content: {
      flexGrow: 1,
      marginTop: APP_BAR_HEIGHT,
      padding: theme.spacing(4, 5, 6),
      maxWidth: '100vw',

      [theme.breakpoints.up('md')]: {
        maxWidth: `calc(100vw - ${drawerWidthDesktopClose}px)`,
        minHeight: `calc(100vh - ${APP_BAR_HEIGHT + FOOTER_HEIGHT}px)`,
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 1920,
        margin: '0 auto',
        marginTop: APP_BAR_HEIGHT,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 56,
        padding: theme.spacing(4, 0, 6),
      },
      [theme.breakpoints.down('xs')]: {
        // padding: theme.spacing(3, 2),
        marginTop: 48,
      },

      '& .MuiPaper-root': {
        [theme.breakpoints.down('sm')]: {
          borderRadius: 0,
        },
      },
    },
    // contentDrawerOpen: {
    //   [theme.breakpoints.up('md')]: {
    //     maxWidth: `min(calc(100% - ${drawerWidthDesktopClose}px), 1920px)`,
    //   },
    // },

    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(1.5, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      height: drawerHeaderHeight,
    },
    menuButton: {
      marginLeft: theme.spacing(-0.5),
      color: theme.palette.text.primary,
    },
    menuButtonOpen: {
      marginRight: theme.spacing(0.5),
    },

    menuBrand: {
      fontWeight: 800,
      fontSize: 24,
      lineHeight: '30px',
      letterSpacing: '-0.04em',
      textTransform: 'lowercase',
      color: theme.palette.text.primary,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      gridArea: 'drawer',
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerPaper: {
      ...mixins.scrollBar,
      [theme.breakpoints.up('md')]: {
        top: drawerHeaderHeight + 1,
        height: `calc(100% - ${APP_BAR_HEIGHT + 1}px)`,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: drawerHeaderHeight,
        justifyContent: 'space-between',
        maxHeight: `calc(100vh -  ${drawerHeaderHeight}px)`,
      },
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
      },
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: drawerWidthDesktopClose,
      [theme.breakpoints.down('sm')]: {
        width: 0,
      },
    },

    drawerContent: {
      // marginTop: APP_BAR_HEIGHT,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minWidth: drawerWidth - theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        minHeight: `calc(100% - ${drawerHeaderHeight + 1}px)`,
      },
    },
    drawerAccountList: {
      paddingBottom: 0,
    },
    drawerItemProgress: {
      width: 100,
    },
    drawerItemProgressSuccess: {
      backgroundColor: fade(theme.palette.success.main, 0.35),
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.palette.success.main,
      },
    },
    drawerItemProgressWarning: {
      backgroundColor: fade(theme.palette.warning.main, 0.35),
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.palette.warning.main,
      },
    },
    drawerItemProgressError: {
      backgroundColor: fade(theme.palette.error.main, 0.35),
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.palette.error.main,
      },
    },
    menuPaper: {
      minWidth: 214,
    },
    profileMenuItem: {
      fontWeight: 'bold',
    },

    itemText: {
      whiteSpace: 'pre-wrap',
    },
    itemTextBold: {
      '&&': {
        fontWeight: 'bold',
      },
    },

    drawerList: {
      // [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(1.5),
      // }
    },
    listItem: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      borderLeft: '2px solid transparent',

      '&:first-child': {
        marginTop: 0,
      },
    },
    iconWrapper: {},
    itemSingleActive: {
      color: theme.palette.brand.main,
      backgroundColor: theme.palette.brand.light,
      // borderLeft: `2px solid ${theme.palette.custom.blueContrast}`,
    },
    itemRoot: {
      color: theme.palette.text.primary,
      borderBottom: `1px solid rgba(3, 57, 61, 0.15)`,
    },
    itemRootOpen: {},
    itemRootClose: {
      padding: theme.spacing(1, 2.5),
      marginTop: theme.spacing(2),
      maxWidth: 64,
      borderBottom: 'none',
      justifyContent: 'center',
    },
    itemRootActive: {
      // color: theme.palette.custom.blueContrast,
      // backgroundColor: theme.palette.custom.blueContrastBackground,
      // borderLeft: `2px solid ${theme.palette.custom.blueContrast}`,
    },
    subItem: {
      width: `calc(100% - ${theme.spacing(2)}px)`,
      margin: theme.spacing(0.5, 1),
      padding: theme.spacing(2),
      borderRadius: 6,
      color: theme.palette.text.secondary,
      fontWeight: 600,

      '&:first-child': {
        marginTop: theme.spacing(1),
      },
      '&:hover': {
        backgroundColor: `${theme.palette.primary.light}88`,
      },
    },
    subItemActive: {
      backgroundColor: theme.palette.brand.light,
    },
    listIcon: {
      minWidth: 24,
      marginRight: theme.spacing(1),
    },
    listIconActive: {
      color: theme.palette.brand.main,
    },
    subList: {
      borderLeft: `2px solid transparent`,
    },
    subListDivider: {
      padding: theme.spacing(0, 2),
      width: '100%',
      height: 1,

      '&::before': {
        display: 'block',
        content: "''",
        width: '100%',
        height: 1,
        background: theme.palette.text.primary,
        opacity: 0.15,
      },
    },
    itemTextSubList: {
      padding: 0,
      fontSize: 16,
      lineHeight: '20px',

      letterSpacing: '-0.02em',
      color: theme.palette.text.body,
    },
    itemTextSubListActive: {
      color: theme.palette.text.body,
    },
    subListDividerActive: {
      // backgroundColor: theme.palette.custom.blueContrastBackground,
      // borderLeft: `2px solid ${theme.palette.custom.blueContrast}`,
    },
    subListActive: {
      // color: theme.palette.custom.blueContrast,
      // backgroundColor: theme.palette.custom.blueContrastBackground,
      // borderLeft: `2px solid ${theme.palette.custom.blueContrast}`,
    },

    gridContainer: {
      display: 'grid',
      minHeight: '100vh',
      gridTemplateColumns: '100vw',
      gridTemplateRows: '64px auto 50px',
      gap: '0px 0px',
      gridTemplateAreas: `"header"
      "content"
      "footer";`,
      fontFamily: 'Inter',
      width: '100%',
      // overflow: 'hidden',

      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '264px',
        gridTemplateRows: '64px auto 50px',
        gridTemplateAreas: `"header header"
      "drawer content"
      "drawer footer";`,
      },
    },
    contentDrawerOpen: {
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '64px',
      },
    },
    header: {
      gridArea: 'header',
    },
    drawerLayout: {
      gridArea: 'drawer',
    },
    footer: {
      zIndex: 1,
      gridArea: 'footer',
    },
    contentLayout: {
      gridArea: 'content',
      padding: theme.spacing(0, 2),
      // overflowX: 'auto',

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 5),
      },
    },
  }),
);
