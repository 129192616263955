import React from 'react';
import { IconButton } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AnvIcon } from '@platform/front-lib';

interface IProps {
  currentUrlSegment?: string;

  toolUrl: string;
  baseUrlSegment: string;
  tutorialsSegments?: string;
  isChapter?: boolean;
  index?: number;
}

const PublicLink: React.FC<IProps> = ({
  toolUrl,
  baseUrlSegment,
  tutorialsSegments,
  currentUrlSegment,
  isChapter = false,
  index,
}) => {
  const urlSegments: string[] = [];

  if (toolUrl) {
    urlSegments.push(toolUrl);
  }

  if (baseUrlSegment) {
    urlSegments.push(baseUrlSegment);
  }

  if (tutorialsSegments) {
    urlSegments.push(tutorialsSegments);
  }

  if (currentUrlSegment && !isChapter) {
    urlSegments.push(currentUrlSegment);
  }

  let url: string = urlSegments.join('/');

  if (currentUrlSegment && isChapter) {
    url = url + '#' + index + '-' + currentUrlSegment;
  }

  return (
    <div>
      <span>{url}</span>
      <CopyToClipboard text={url}>
        <IconButton>
          <AnvIcon icon={'surveyCopy'} size={16} color={'primary'} />
        </IconButton>
      </CopyToClipboard>
    </div>
  );
};

export default PublicLink;
