import { ROUTES } from './routes';
import { USER_ROLES } from './auth';
import { TOOL_MODULES } from './tool';

const drawerClient: any[] = [
  {
    key: 'BUILD',
    module: TOOL_MODULES.BUILD,
    name: 'Build',
    nameI18n: 'KNOWLEDGE_BASE.DRAWER.BUILD',
    to: ROUTES.MODULE_BUILD,
    toPrefix: [ROUTES.MODULE_BUILD_PREFIX],
    icon: 'dotsGrid',
    position: 1,
    items: [
      {
        key: 'knowledgeBase',
        name: 'Knowledge Base',
        nameI18n: 'KNOWLEDGE_BASE.DRAWER.BUILD',
        to: ROUTES.MODULE_BUILD_USER_LIST,
        toPrefix: [ROUTES.MODULE_BUILD_PREFIX],
        icon: 'reviewList',
        disabled: false,
      },
    ],
  },
  {
    key: 'VIEW',
    module: TOOL_MODULES.VIEW,
    name: 'View',
    nameI18n: 'KNOWLEDGE_BASE.DRAWER.VIEW',
    to: ROUTES.MODULE_VIEW,
    toPrefix: [ROUTES.VIEW_KNOWLEDGE_BASE_LIST, ROUTES.MODULE_VIEW],
    icon: 'dotsGrid',
    position: 2,

    items: [
      {
        key: 'knowledgeBase',
        name: 'Knowledge Base',
        nameI18n: 'KNOWLEDGE_BASE.DRAWER.VIEW',
        to: ROUTES.VIEW_KNOWLEDGE_BASE_LIST,
        icon: 'reviewList',
      },
    ],
  },
  {
    key: 'access',
    module: TOOL_MODULES.ACCESS,
    name: 'Access',
    nameI18n: 'KNOWLEDGE_BASE.DRAWER.ACCESS',
    to: ROUTES.ROUTE_ACCESS_PREFIX,
    icon: 'moduleAccess',
    position: 3,
    disabled: true,
    // items: [
    //   {
    //     key: 'user_access',
    //     name: 'User Access',
    //     nameI18n: 'KNOWLEDGE_BASE.DRAWER.ACCESS.LIST',
    //     to: ROUTES.ADMIN_ACCESS_LIST,
    //     icon: 'userAccess',
    //     disabled: true,
    //   },
    // ],
  },
];

export const drawerAllEntries = {
  [USER_ROLES.ROLE_CLIENT]: drawerClient,
};
