import React from 'react';
import { Grid } from '@material-ui/core';
import { AnvHtml, AnvUtils, TextH4 } from '@platform/front-lib';
import { useInView } from 'react-intersection-observer';

import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import slugify from '@sindresorhus/slugify';

import { ModuleContext } from '../../../../common/module-context';
import { IKnowledgeChapter } from '../../../../../models';

import { AnvChipUnpublished } from '../../../../common/chip-unpublished';

import { TOOL_MODULES } from '../../../../../constants';
import { useStyles } from './styles';

interface IAnvKnowledgeChapterProps {
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  chapter: IKnowledgeChapter;
  index: number;
}

export const AnvKnowledgeChapter: React.FC<IAnvKnowledgeChapterProps> = ({
  chapter,
  index,
  setTabValue,
}) => {
  const { locale } = useIntl();
  const classes = useStyles();

  const location = useLocation();

  const locationHash = location.hash;

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const titleId = slugify(`${index}-${chapter.title?.en}`);

  React.useLayoutEffect(() => {
    if (locationHash && locationHash === `#${titleId}`) {
      const elem = document.getElementById(titleId);

      elem?.scrollIntoView?.({
        behavior: 'smooth',
        block: 'center',
      });
    }
    // only on init
    // eslint-disable-next-line
  }, []);

  const module = React.useContext(ModuleContext);
  const isBuildModule = module === TOOL_MODULES.BUILD;

  React.useEffect(() => {
    if (inView) {
      setTabValue(index);
    }
  }, [inView, index, setTabValue]);

  return (
    <Grid
      container
      id={titleId}
      key={chapter._id}
      className={classes.contentWrapper}
      ref={ref}
    >
      <Grid item xs={12}>
        <TextH4>
          {AnvUtils.getNameByLangNullable(chapter, 'title', locale)}
          {!chapter?.published && isBuildModule && <AnvChipUnpublished />}
        </TextH4>
      </Grid>

      <Grid item xs={12}>
        <AnvHtml
          text={AnvUtils.getNameByLangNullable(chapter, 'description', locale)}
        />
      </Grid>
    </Grid>
  );
};
