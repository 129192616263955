import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { Grid, Hidden, IconButton, MenuItem } from '@material-ui/core';

import {
  AnvButton,
  AnvPaginationMui,
  AnvIcon,
  ModalConfirm,
  useModal,
  AnvTableWrapper,
  AnvTableTitle,
  AnvMenu,
  useMenu,
  DefaultIcons,
  AnvTable,
  useCheckIsTableView,
  ISelectOption,
  AnvUtils,
  AnvBreadcrumbs,
  useCommonTable,
} from '@platform/front-lib';

import { ModuleContext } from '../../common/module-context';

import { useStores } from '../../../stores';

import { ExplorerListAddNew } from './AddNew';
import { prepareAccessFilterParam, prepareOptions } from '../../../utils';

import { PAGINATION_LIMIT, ROUTES } from '../../../constants';
import { orgListColumns } from '../../build/list/columns';
import { useStyles } from './styles';

interface IPageBrowsingList {}

const defaultFilterState = {
  accessScope: '',
  organizationType: '',
  name: '',
  directoryAccessible: '',
  directoryPublishedAt: null,
  directoryUpdatedAt: null,
};

export const PageViewList: React.FC<IPageBrowsingList> = observer(() => {
  const {
    authStores: { modulesFilter, isOperatorLoggedIn },
    viewStore: {
      rootStore: {
        getList,
        addToList,
        isFetchingList,
        data: organizations,
        pagination,
        isLoadedList,
        filter: storeFilter,
        sort: storeSorting,
        setPage,
        setFilter,
        setSort,
      },
    },
    metaStores: {
      accessScopeStore: {
        data: accessScopes,
        accessScopesMap,
        getAccessScopes,
        isLoaded: isLoadedAccessScopes,
      },
      organizationTypesStore: {
        data: organizationTypes,
        organizationTypesMap,
        getOrganizationTypes,
        isLoaded: isLoadedOrganizationTypes,
      },
      accessHistoryStore: { getRecentlyViewed, hideAccessHistoryForTool },
    },
    uiStores: { tool },
    userStore: { userInfo, getUserInfo },
  } = useStores();

  React.useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  const classes = useStyles();

  const { formatMessage, locale } = useIntl();

  const module = React.useContext(ModuleContext);

  // Request modal

  const { closeMenu, handleClose: handleCloseMenu } = useMenu();

  const handleAddToList = ({
    organization,
    school,
    accessScope,
  }: {
    organization: string;
    school: string;
    accessScope: string;
  }) => {
    const successFunc = () => {
      getList({
        accessFilter: prepareAccessFilterParam(accessFilter),
        page: 1,
        sort: querySort,
        filter: memoQueryFilter,
        limit: PAGINATION_LIMIT,
      });

      handleToggleFilters();
    };

    addToList({ organization, school, accessScope }, { successFunc });
  };

  const accessFilter = modulesFilter?.[module];
  React.useEffect(() => {
    if (!isLoadedAccessScopes) {
      getAccessScopes();
    }
  }, [isLoadedAccessScopes, getAccessScopes]);

  React.useEffect(() => {
    if (!isLoadedOrganizationTypes) {
      getOrganizationTypes();
    }
  }, [isLoadedOrganizationTypes, getOrganizationTypes]);

  React.useEffect(() => {
    const payload = {
      moduleCode: module,
      isOrganization: true,
    };

    getRecentlyViewed(payload);
  }, [module, getRecentlyViewed]);

  const {
    querySort,
    // queryFilter,
    queryPage,

    filterState,
    memoQueryFilter,
    filterMemo,
    handleChangeFilterDate,
    handleChangeFilter,
    handleChangePhone,
    handleChangePage,
  } = useCommonTable({
    defaultFilterState,
    pagination,
    setPage,
    setFilter,
    setSort,
    storeFilter,
    storeSorting,
  });

  /** get entities */
  React.useEffect(() => {
    getList({
      accessFilter: prepareAccessFilterParam(accessFilter),
      page: +queryPage,
      sort: querySort,
      filter: memoQueryFilter,
      limit: PAGINATION_LIMIT,
    });
  }, [accessFilter, queryPage, memoQueryFilter, querySort, getList]);

  /** COLUMNS  */
  const accessScopesOptions = React.useMemo(() => {
    return prepareOptions(accessScopes, {
      locale,
      field: 'description',
    });
  }, [accessScopes, locale]);

  const organizationTypesOptions = React.useMemo(() => {
    const options: ISelectOption[] = AnvUtils.prepareOptions(
      organizationTypes,
      {
        locale,
        field: 'description',
      },
    );

    options.push({
      label: formatMessage({
        id: 'KNOWLEDGE_BASE.ORGANIZER.LIST.FILTER.SCHOOL',
        defaultMessage: 'School',
      }),
      value: 'SCHOOL',
    });

    return options;
  }, [organizationTypes, locale, formatMessage]);
  // const organizationTypesOptions = React.useMemo(() => {
  //   return prepareOptions(organizationTypes, {
  //     locale,
  //     field: 'description',
  //   });
  // }, [organizationTypes, locale]);

  const columns = React.useMemo(() => {
    return orgListColumns(locale, formatMessage, {
      accessScopesOptions,
      accessScopesMap,
      organizationTypesOptions,
      organizationTypesMap,
      isView: true,
      toolUrl: tool?.link || '',
    });
  }, [
    formatMessage,
    locale,
    accessScopesOptions,
    accessScopesMap,
    organizationTypesOptions,
    organizationTypesMap,
    tool?.link,
  ]);

  const data = React.useMemo(() => {
    return organizations || [];
  }, [organizations]);

  const {
    isOpen: openFilters,
    handleToggleModal: handleToggleFilters,
  } = useModal();

  /** Remove from list */
  const [hideHistoryId, setHideHistoryId] = React.useState<string | null>(null);
  const [isHideModal, setIsHideModalOpen] = React.useState(false);
  const handleCloseHideModal = () => {
    setIsHideModalOpen(false);
    setHideHistoryId(null);
  };

  const handleRemoveFromList = (id: string) => () => {
    handleCloseMenu();
    setHideHistoryId(id);
    setIsHideModalOpen(true);
  };

  const confirmHideOrganization = () => {
    if (hideHistoryId) {
      const successFunc = () => {
        getList({
          accessFilter: prepareAccessFilterParam(accessFilter),
          page: +queryPage,
          sort: querySort,
          filter: memoQueryFilter,
          limit: PAGINATION_LIMIT,
        });
        setIsHideModalOpen(false);
      };

      hideAccessHistoryForTool(
        {
          id: hideHistoryId,
          moduleCode: module,
          organization: hideHistoryId,
        },
        { successFunc },
      );
      setHideHistoryId(null);
    }
  };

  const { isShowTable } = useCheckIsTableView({
    tableDataIsLoaded: isLoadedList,
    tableDataLength: data?.length || 0,
    memoQueryFilter,
  });

  return (
    <>
      <AnvBreadcrumbs
        tool={tool}
        moduleCode={module}
        isOperator={isOperatorLoggedIn}
      />

      <AnvTableWrapper>
        <AnvTableTitle
          tableTitle={
            <Grid
              container
              direction={'row'}
              wrap={'nowrap'}
              justify={'space-between'}
              alignItems={'center'}
            >
              <Grid item>
                <FormattedMessage
                  id="KNOWLEDGE_BASE.VIEW.ORGS.LIST.TITLE"
                  defaultMessage="Organizations"
                />
              </Grid>

              <Grid item>
                <Hidden smDown>
                  <AnvButton
                    data-test={'add-new-organization-open'}
                    onClick={handleToggleFilters}
                    variant={'contained'}
                    size={'small'}
                    className={classes.toolbarFilterButton}
                    startIcon={
                      <AnvIcon icon={'plus'} size={16} color={'inherit'} />
                    }
                  >
                    <span className={classes.toolbarFilterButtonLabel}>
                      <FormattedMessage
                        id="KNOWLEDGE_BASE.VIEW.ORGS.LIST.ACTION.ADD"
                        defaultMessage="Find organization"
                      />
                    </span>
                  </AnvButton>
                </Hidden>

                <Hidden mdUp>
                  <IconButton
                    data-test={'add-new-organization-open'}
                    onClick={handleToggleFilters}
                    className={classes.toolbarFilterButtonMobile}
                  >
                    <AnvIcon icon={'plus'} size={16} color={'white'} />
                  </IconButton>
                </Hidden>
              </Grid>
            </Grid>
          }

          // rightActions={}
        ></AnvTableTitle>

        {userInfo && (
          <ExplorerListAddNew
            isLoadingData={isFetchingList}
            userInfo={userInfo}
            handleAddToList={handleAddToList}
            total={pagination?.totalDocs}
            handleToggleFilters={handleToggleFilters}
            openFilters={openFilters}
          />
        )}

        <AnvTable
          filterMemo={filterMemo}
          setFilter={setFilter}
          isShowTable={isShowTable}
          filterState={filterState}
          handleChangeFilterDate={handleChangeFilterDate}
          handleChangeFilter={handleChangeFilter}
          handleChangePhone={handleChangePhone}
          //common
          data={data}
          columns={columns}
          querySort={querySort}
          setSort={setSort}
          TableActionComponent={({ data }: any) => (
            <AnvMenu closeMenu={closeMenu}>
              {data?.baseId && (
                <MenuItem
                  component={'a'}
                  target={'_blank'}
                  href={generatePath(ROUTES.VIEW_KNOWLEDGE_TUTORIAL_LIST_ROOT, {
                    baseId: data?.baseSegment,
                  })}
                  title={formatMessage({
                    id: 'KNOWLEDGE_BASE.VIEW.LIST.ACTION.VIEW_DETAILS',
                    defaultMessage: 'View Details',
                  })}
                  onClick={() => {
                    handleCloseMenu();
                  }}
                >
                  <AnvIcon icon={DefaultIcons.VIEW_DETAILS} color={'inherit'} />
                  <span>
                    <FormattedMessage
                      id="KNOWLEDGE_BASE.VIEW.LIST.ACTION.VIEW_DETAILS"
                      defaultMessage="View Details"
                    />
                  </span>
                </MenuItem>
              )}

              {(data?.historyId || data?._id) && (
                <MenuItem
                  onClick={handleRemoveFromList(data.historyId || data?._id)}
                  title={formatMessage({
                    id: 'KNOWLEDGE_BASE.VIEW.LIST.ACTION.ARCHIVE',
                    defaultMessage: 'Archive',
                  })}
                >
                  <AnvIcon icon={'filterArchive'} color={'primary'} />
                  <span>
                    <FormattedMessage
                      id="KNOWLEDGE_BASE.VIEW.LIST.ACTION.ARCHIVE"
                      defaultMessage="Archive"
                    />
                  </span>
                </MenuItem>
              )}
            </AnvMenu>
          )}
          isLoadedList={isLoadedList}
          noDataMessage={formatMessage({
            id: 'KNOWLEDGE_BASE.VIEW.LIST.EMPTY',
            defaultMessage: 'There is no added organizations yet',
          })}
        />

        <AnvPaginationMui
          pagination={pagination}
          totalPages={pagination?.totalPages}
          handleChangePage={handleChangePage}
          page={+queryPage}
        />

        <ModalConfirm
          text={
            <FormattedMessage
              id="KNOWLEDGE_BASE.VIEW.LIST.QUESTION.ARCHIVE"
              defaultMessage="Are you sure to hide this organization?"
            />
          }
          isOpen={isHideModal}
          handleClose={handleCloseHideModal}
          confirmAction={confirmHideOrganization}
          cancelAction={handleCloseHideModal}
        />
      </AnvTableWrapper>
    </>
  );
});
