import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';
import { FormattedMessage, useIntl } from 'react-intl';
import { MenuItem } from '@material-ui/core';
import { generatePath, NavLink, useHistory } from 'react-router-dom';
import {
  AnvBreadcrumbs,
  AnvIcon,
  AnvMenu,
  AnvPaginationMui,
  AnvTable,
  AnvTableTitle,
  AnvTableWrapper,
  AnvUtils,
  Constants,
  DefaultIcons,
  ISelectOption,
  useCheckIsTableView,
  useCommonTable,
  useMenu,
} from '@platform/front-lib';
import { ModuleContext } from '../../common/module-context';
import { KNOWLEDGE_BASE_MESSAGES, ROUTES } from '../../../constants';
import { IKnowledgeBase } from '../../../models';
import { orgListColumns } from './columns';
import { ListToolbarFilterCounter } from '../../common/tool-list-toolbar/ListToolbarFilterCounter';

interface IBuildListProps {}

const defaultFilterState = {
  organizationType: '',
  name: '',
};

export const PageBuildList: React.FC<IBuildListProps> = observer(() => {
  const { formatMessage, locale } = useIntl();

  const history = useHistory();

  const { closeMenu, handleClose: handleCloseMenu } = useMenu();

  const {
    authStores: { modulesFilter, isOperatorLoggedIn },
    buildStore: {
      rootStore: {
        getList,
        isLoadedList,
        data: entities,
        pagination,
        setPage,
        setFilter,
        setSort,
        createNewRootBase,
        filter: storeFilter,
        sort: storeSorting,
      },
      // knowledgeBaseStore: { publish },
    },
    metaStores: {
      organizationTypesStore: {
        data: organizationTypes,
        organizationTypesMap,
        getOrganizationTypes,
        isLoaded: isLoadedOrganizationTypes,
      },
    },
    uiStores: { tool },
  } = useStores();

  React.useEffect(() => {
    if (!isLoadedOrganizationTypes) {
      getOrganizationTypes();
    }
  }, [isLoadedOrganizationTypes, getOrganizationTypes]);

  const module = React.useContext(ModuleContext);

  const accessFilter = modulesFilter?.[module];

  const {
    querySort,
    queryPage,
    filterMemo,
    filterState,
    memoQueryFilter,
    handleChangeFilterDate,
    handleChangeFilter,
    handleChangePhone,
    handleChangePage,
  } = useCommonTable({
    defaultFilterState,
    pagination,
    setPage,
    setFilter,
    setSort,
    storeFilter,
    storeSorting,
  });

  /** get entities */
  React.useEffect(() => {
    getList({
      accessFilter: AnvUtils.prepareAccessFilterParam(accessFilter),
      page: +queryPage,
      sort: querySort,
      filter: memoQueryFilter,
      limit: Constants.PAGINATION_LIMIT,
    });
  }, [accessFilter, queryPage, memoQueryFilter, querySort, getList]);

  /** COLUMNS  */
  const organizationTypesOptions = React.useMemo(() => {
    const options: ISelectOption[] = AnvUtils.prepareOptions(
      organizationTypes,
      {
        locale,
        field: 'description',
      },
    );

    options.push({
      label: formatMessage({
        id: 'KNOWLEDGE_BASE.BUILD.LIST.SCHOOL_FILTER',
        defaultMessage: 'School',
      }),
      value: 'SCHOOL',
    });

    return options;
  }, [organizationTypes, locale, formatMessage]);

  const columns = React.useMemo(() => {
    return orgListColumns(locale, formatMessage, {
      organizationTypesOptions,
      organizationTypesMap,
      toolUrl: tool?.link || '',
    });
  }, [
    formatMessage,
    tool?.link,
    locale,
    organizationTypesOptions,
    organizationTypesMap,
  ]);

  const data = React.useMemo(() => {
    return entities || [];
  }, [entities]);

  const handleCreateRootBase = (orgId: string) => () => {
    handleCloseMenu();
    const successFunc = (response: IKnowledgeBase) => {
      const url = generatePath(ROUTES.KNOWLEDGE_TUTORIAL_LIST, {
        baseId: response?.urlSegmentId,
      });
      history.push(url);
    };

    createNewRootBase({ id: orgId }, { successFunc });
  };

  const { isShowTable } = useCheckIsTableView({
    tableDataIsLoaded: isLoadedList,
    tableDataLength: entities?.length || 0,
    memoQueryFilter,
  });

  /** menu */
  // const {
  //   handleOpenModal: handleOpenModalUnpublishBase,
  //   // isOpen: isOpenUnpublishBase,
  //   // handleCloseModal: handleCloseModalUnpublishBase,
  // } = useModal();

  // const {
  //   setIsOpen: setOpenModalPublishError,
  //   isOpen: isOpenPublishError,
  //   handleCloseModal: handleCloseModalPublishError,
  // } = useModal();

  // const handlePublishBase = ({ id }: { id: string }) => {
  //   if (!id) {
  //     return;
  //   }
  //
  //   publish(
  //     { id, entityKey: '' },
  //     {
  //       successFunc: () => {
  //         handleCloseMenu();
  //       },
  //       failFunc: () => {
  //         handleCloseMenu();
  //       },
  //     },
  //   );
  // };

  // const handleUnpublishBase = ({ id }: { id: string }) => {
  //   if (!id) {
  //     return;
  //   }
  //
  //   unpublish(
  //     { id, entityKey: '' },
  //     {
  //       successFunc: () => {
  //         handleCloseUnpublishModal();
  //       },
  //     },
  //   );
  // };

  // const handleOpenUnpublishModal = ({ id }: { id: string }) => {
  //   if (!id) {
  //     return;
  //   }
  //   handleCloseMenu();
  //   setBaseId(id);
  //   handleOpenModalUnpublishBase();
  // };

  // const handleCloseUnpublishModal = () => {
  //   setBaseId(null);
  //   handleCloseModalUnpublishBase();
  // };

  const toForm = ({ id }: { id?: string }) => {
    return generatePath(ROUTES.KNOWLEDGE_BASE_FORM, { id });
  };
  const handleToForm = ({
    id,
    idEntity,
  }: {
    id?: string;
    idEntity?: string;
  }) => async () => {
    if (!id) {
      if (idEntity) {
        await createNewRootBase(
          { id: idEntity },
          {
            successFunc: (res) => {
              if (res) {
                const route = generatePath(ROUTES.KNOWLEDGE_BASE_FORM, {
                  id: res.urlSegmentId,
                });
                history.push(route);
              }
            },
          },
        );
      }

      return;
    }
  };

  // const toCreateTutorial = ({ id }: { id: string }) => {
  //   if (id) {
  //     return generatePath(ROUTES.KNOWLEDGE_TUTORIAL_FORM, {
  //       baseId: id,
  //     });
  //   }
  //
  //   return '';
  // };
  //
  // const handleToCreateTutorial = ({
  //   id,
  //   idEntity,
  // }: {
  //   id?: string;
  //   idEntity?: string;
  // }) => async () => {
  //   if (idEntity) {
  //     await createNewRootBase(
  //       { id: idEntity },
  //       {
  //         successFunc: (res: IKnowledgeBase) => {
  //           if (res) {
  //             const route = generatePath(ROUTES.KNOWLEDGE_TUTORIAL_FORM, {
  //               baseId: res.urlSegmentId,
  //             });
  //             history.push(route);
  //           }
  //         },
  //       },
  //     );
  //   }
  //
  //   return;
  // };

  // const toViewTutorialList = ({ baseId }: { baseId: string }) => {
  //   return generatePath(ROUTES.KNOWLEDGE_TUTORIAL_LIST, {
  //     baseId: baseId,
  //   });
  // };

  const toPreview = ({ baseId }: { baseId: string }) => {
    return generatePath(ROUTES.KNOWLEDGE_BUILD_PREVIEW, {
      baseId: baseId,
    });
  };

  return (
    <>
      <AnvBreadcrumbs
        moduleCode={module}
        tool={tool}
        isOperator={isOperatorLoggedIn}
      />

      <AnvTableWrapper>
        <AnvTableTitle
          tableTitle={
            <FormattedMessage
              id="KNOWLEDGE_BASE.BUILD.LIST.TITLE"
              defaultMessage="Entities List"
            />
          }
        >
          <ListToolbarFilterCounter total={pagination?.totalPages || 0} />
        </AnvTableTitle>

        {isShowTable && (
          <AnvTable
            isShowTable={isShowTable}
            setFilter={setFilter}
            filterMemo={filterMemo}
            filterState={filterState}
            handleChangeFilterDate={handleChangeFilterDate}
            handleChangeFilter={handleChangeFilter}
            handleChangePhone={handleChangePhone}
            //common
            data={data}
            columns={columns}
            querySort={querySort}
            setSort={setSort}
            TableActionComponent={({ data }) => (
              <AnvMenu closeMenu={closeMenu}>
                {data?.baseId && (
                  <MenuItem
                    component={NavLink}
                    to={generatePath(ROUTES.KNOWLEDGE_TUTORIAL_LIST, {
                      baseId: data?.baseSegment,
                    })}
                    title={formatMessage({
                      id: 'KNOWLEDGE_BASE.BUILD.LIST.ACTION.VIEW_DETAILS',
                      defaultMessage: 'View Details',
                    })}
                    onClick={() => {
                      handleCloseMenu();
                    }}
                  >
                    <AnvIcon
                      icon={DefaultIcons.VIEW_DETAILS}
                      color={'inherit'}
                    />
                    <span>
                      {formatMessage({ ...KNOWLEDGE_BASE_MESSAGES.TUTORIALS })}
                    </span>
                  </MenuItem>
                )}

                {!data?.baseId && (
                  <MenuItem
                    onClick={handleCreateRootBase(data?._id)}
                    title={formatMessage({
                      id: 'KNOWLEDGE_BASE.BUILD.LIST.ACTION.VIEW_DETAILS',
                      defaultMessage: 'View Details',
                    })}
                  >
                    <AnvIcon
                      icon={DefaultIcons.VIEW_DETAILS}
                      color={'inherit'}
                    />
                    <span>
                      {formatMessage({ ...KNOWLEDGE_BASE_MESSAGES.TUTORIALS })}
                    </span>
                  </MenuItem>
                )}

                {data?.baseSegment ? (
                  <MenuItem
                    component={NavLink}
                    to={toForm({
                      id: data.baseSegment,
                    })}
                  >
                    <AnvIcon
                      icon={DefaultIcons.EDIT}
                      size={24}
                      color={'inherit'}
                    />
                    {formatMessage({ ...KNOWLEDGE_BASE_MESSAGES.GENERAL_INFO })}
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={handleToForm({
                      id: data.baseSegment,
                      idEntity: data._id,
                    })}
                  >
                    <AnvIcon
                      icon={DefaultIcons.EDIT}
                      size={24}
                      color={'inherit'}
                    />
                    {formatMessage({ ...KNOWLEDGE_BASE_MESSAGES.GENERAL_INFO })}
                  </MenuItem>
                )}

                {data?.baseSegment && (
                  <MenuItem
                    component={'a'}
                    target={'_blank'}
                    href={toPreview({
                      baseId: data?.baseSegment,
                    })}
                    onClick={handleCloseMenu}
                  >
                    <AnvIcon
                      icon={DefaultIcons.PREVIEW}
                      size={24}
                      color={'inherit'}
                    />
                    {formatMessage({ ...KNOWLEDGE_BASE_MESSAGES.PREVIEW_BASE })}
                  </MenuItem>
                )}

                {/*{data?.baseSegment ? (*/}
                {/*  <MenuItem*/}
                {/*    component={NavLink}*/}
                {/*    to={toCreateTutorial({*/}
                {/*      id: data?.baseSegment,*/}
                {/*    })}*/}
                {/*  >*/}
                {/*    <AnvIcon icon={'plus'} size={24} color={'inherit'} />*/}
                {/*    {formatMessage({ ...KNOWLEDGE_BASE_MESSAGES.TUTORIAL_ADD })}*/}
                {/*  </MenuItem>*/}
                {/*) : (*/}
                {/*  <MenuItem*/}
                {/*    onClick={handleToCreateTutorial({*/}
                {/*      idEntity: data._id,*/}
                {/*    })}*/}
                {/*  >*/}
                {/*    <AnvIcon icon={'plus'} size={24} color={'inherit'} />*/}
                {/*    {formatMessage({ ...KNOWLEDGE_BASE_MESSAGES.TUTORIAL_ADD })}*/}
                {/*  </MenuItem>*/}
                {/*)}*/}
              </AnvMenu>
            )}
            isLoadedList={isLoadedList}
            noDataMessage={formatMessage({
              id: 'KNOWLEDGE_BASE.BUILD.LIST.EMPTY',
              defaultMessage: 'There is no entities in this list',
            })}
          />
        )}

        <AnvPaginationMui
          pagination={pagination}
          totalPages={pagination?.totalPages}
          handleChangePage={handleChangePage}
          page={+queryPage}
        />
      </AnvTableWrapper>
    </>
  );
});
