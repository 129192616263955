import { Paper } from '@material-ui/core';
import {
  AnvBreadcrumbs,
  AnvUtils,
  AnvViewHeader,
  TextParagraph,
  useCommonOffsetsStyles,
} from '@platform/front-lib';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useStores } from '../../../stores';

interface INotFoundBase {}

interface INotFoundBaseProps {
  educationEntityId: string;
}

export const NotFoundBase: React.FC<INotFoundBase> = observer(() => {
  const { educationEntityId } = useParams<INotFoundBaseProps>();

  const classesOffset = useCommonOffsetsStyles();

  const { locale } = useIntl();

  const {
    uiStores: { tool },
    metaStores: {
      schoolsStore: { getSchool },
      organizationsStore: { getOrganization },
    },
  } = useStores();

  const [entity, setEntity] = React.useState<Record<string, any> | null>(null);

  React.useEffect(() => {
    getOrganization(
      {
        id: educationEntityId,
      },
      {
        successFunc: (data) => {
          if (data) {
            setEntity(data);
          }
        },
      },
    );
  }, [educationEntityId, getOrganization]);

  React.useEffect(() => {
    getSchool(
      { id: educationEntityId },
      {
        successFunc: (data) => {
          if (data) {
            setEntity(data);
          }
        },
      },
    );
  }, [educationEntityId, getSchool]);

  const entityName = React.useMemo(() => {
    return AnvUtils.getNameByLangNullable(entity, 'name', locale) || '';
  }, [entity, locale]);

  return (
    <>
      <AnvBreadcrumbs
        tool={tool}
        moduleCode={undefined}
        items={[{ component: entityName }]}
      />

      <Paper>
        <AnvViewHeader title={entityName} textAlign={'left'} />
        <TextParagraph className={classesOffset.p_4} component={'div'}>
          <FormattedMessage
            id="KNOWLEDGE_BASE.VIEW.NOT_FOUND"
            defaultMessage="Knowledge base for the organization doesn't exist."
          />
        </TextParagraph>
      </Paper>
    </>
  );
});
