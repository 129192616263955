import { IGetList } from 'src/service/interfaces';
import { axiosCatch } from 'src/service/utils';
import { axiosInstance } from '@platform/front-lib';
import { IBaseCommonPayload, IKnowledgeBase } from 'src/models';
import { ICommonService } from '../../../models';

const axios = axiosInstance;

export class KnowledgeBaseService {
  private readonly apiUrl: string;

  private readonly apiUrlNew: string;

  constructor({ isView = false }: ICommonService) {
    this.apiUrl = isView
      ? process.env.REACT_APP_API_TOOL_ENDPOINT + '/view/knowledge-base'
      : process.env.REACT_APP_API_TOOL_ENDPOINT + '/build/knowledge-base';
    this.apiUrlNew = isView
      ? process.env.REACT_APP_API_TOOL_ENDPOINT + '/view/education-entity'
      : process.env.REACT_APP_API_TOOL_ENDPOINT + '/build/education-entity';
  }

  async getAll(data?: IGetList) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/list`, {
        params: data,
      }),
    );
  }

  async getOne({ id }: IBaseCommonPayload) {
    return axiosCatch(axios.get(`${this.apiUrl}/${id}`, {}));
  }

  async getOneByEducationEntity({ id }: IBaseCommonPayload) {
    return axiosCatch(axios.get(`${this.apiUrlNew}/${id}/knowledge-base`, {}));
  }

  async create(payload: Omit<IKnowledgeBase, 'tutorials' | '_id'>) {
    return axiosCatch(axios.post(`${this.apiUrl}`, payload, {}));
  }

  async update(payload: Omit<IKnowledgeBase, 'tutorials'>) {
    const { _id, ...data } = payload;
    return axiosCatch(axios.patch(`${this.apiUrl}/${_id}`, data, {}));
  }

  async publish({ id }: { id: string }) {
    return axiosCatch(axios.patch(`${this.apiUrl}/publish/${id}`));
  }
  async unpublish({ id }: { id: string }) {
    return axiosCatch(axios.patch(`${this.apiUrl}/unpublish/${id}`));
  }

  async delete(id: string) {
    return axiosCatch(axios.delete(`${this.apiUrl}/${id}`, {}));
  }
}
