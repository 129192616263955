import React from 'react';
import { observer } from 'mobx-react-lite';

import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Grid } from '@material-ui/core';

import type { IRequestAccessModuleScope } from '@platform/front-lib/dist/models';

import {
  AnvButton,
  AnvDialog,
  ScopeFormFilter,
  usePrevious,
  AnvDialogTitle,
  AnvDialogBody,
  AnvDialogActions,
  Constants,
} from '@platform/front-lib';

import { useStyles } from './styles';
import { ModuleContext } from '../../common/module-context';
import { useStores } from '../../../stores';

export interface IFilterForm {
  accessScope: string;
  scope?: IRequestAccessModuleScope;
}

interface IProps {
  total?: number;
  userInfo?: Record<string, any>;
  openFilters: boolean;
  isLoadingData: boolean;
  handleToggleFilters: () => void;
  handleAddToList: (payload: any /*IBrowserAddToList*/) => void;
}

interface IValidationSchemaModuleRequest {
  accessScope?: string;
}
const validationSchema = (
  fm: (arg: any, values?: any) => string,
  { accessScope }: IValidationSchemaModuleRequest,
) => {
  const shape: Record<string, any> = {
    accessScope: yup.string().nullable().optional(),
  };

  let scopeObjectShape = null;

  if (accessScope === Constants.ACCESS_SCOPES.SCHOOL) {
    scopeObjectShape = {
      jurisdiction: yup.string().nullable(),

      school: yup
        .string()
        .typeError(fm(Constants.validationsMessages.required()))
        .required(fm(Constants.validationsMessages.required())),
    };
  }
  if (accessScope !== Constants.ACCESS_SCOPES.SCHOOL) {
    scopeObjectShape = {
      orgType: yup.string().nullable(),
      organization: yup
        .string()
        .typeError(fm(Constants.validationsMessages.required()))
        .required(fm(Constants.validationsMessages.required())),
    };
  }

  if (scopeObjectShape) {
    shape.scope = yup
      .object()
      .shape(scopeObjectShape)
      .required(fm(Constants.validationsMessages.required()));
  }

  return yup.object().shape(shape);
};

export const ExplorerListAddNew: React.FC<IProps> = observer(
  ({
    total,
    userInfo,
    openFilters,
    isLoadingData,
    handleAddToList,
    handleToggleFilters,
  }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const module = React.useContext(ModuleContext);

    const [accessScopeState, setAccessScopeState] = React.useState(
      Constants.ACCESS_SCOPES.SCHOOL,
    );
    const defaultValues = {
      accessScope: Constants.ACCESS_SCOPES.SCHOOL,
      scope: {
        jurisdiction: userInfo?.jurisdiction || '',
        organization: null,
        organizationSecondary: null,
        tags: [],
        school: null,
        particularOrganization: false,
      },
    };
    const methods = useForm<IFilterForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(
        validationSchema(formatMessage, {
          accessScope: accessScopeState,
        }),
      ),
    });

    const { handleSubmit, control, setValue } = methods;

    const accessScope = useWatch({
      control,
      name: 'accessScope',
      defaultValue: '',
    });

    const accessScopePrev = usePrevious(accessScope);
    const accessScopeChanged =
      accessScope && accessScopePrev && accessScopePrev !== accessScope;
    React.useEffect(() => {
      setAccessScopeState(accessScope);

      if (accessScopeChanged) {
        setValue('scope.jurisdiction', '');
        setValue('scope.organization', '');
        setValue('scope.organizationSecondary', '');
        setValue('scope.school', '');
      }
      // [setAccessScopeState]
      // eslint-disable-next-line
    }, [accessScope, accessScopeChanged, setValue]);

    const {
      uiStores: { tool, anvilarModulesMap },
      metaStores,
    } = useStores();

    const {
      accessScopeStore,
      jurisdictionStore: { data: jurisdictions, getJurisdictions },
    } = metaStores;

    const { data: accessScopes, getAccessScopes } = accessScopeStore;

    React.useEffect(() => {
      getJurisdictions();
      getAccessScopes();
    }, [getJurisdictions, getAccessScopes]);

    const moduleSettings = anvilarModulesMap?.[module]?.settingsMap;

    const submitFilter = (fields: any) => {
      handleAddToList({
        organization: fields?.scope?.organization,
        school: fields?.scope?.school,
        accessScope: fields.accessScope,
      });
    };

    if (!openFilters) {
      return null;
    }

    return (
      <AnvDialog handleClose={handleToggleFilters} open={openFilters}>
        <AnvDialogTitle>
          <FormattedMessage
            id="KNOWLEDGE_BASE.VIEW.MODAL.ADD_NEW_ORG.TITLE"
            defaultMessage="Add new organization"
          />
        </AnvDialogTitle>

        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(submitFilter)}
            noValidate
            autoComplete={'off'}
          >
            <AnvDialogBody>
              <ScopeFormFilter
                organizationRequired={true}
                schoolRequired={true}
                metaStores={metaStores}
                onlyMyAccessByModule={module}
                withHistory={true}
                hideTool
                hideModule
                displayEmptyAccessScope={false}
                particularTool={tool?.code}
                particularModule={module}
                accessScopeStore={accessScopeStore}
                accessScopes={accessScopes}
                jurisdictions={jurisdictions}
                moduleSettings={moduleSettings}
              />
            </AnvDialogBody>

            <AnvDialogActions>
              <Grid
                container
                spacing={2}
                className={classes.filterModalActions}
              >
                <Grid item xs={12}>
                  <AnvButton
                    data-test={'add-new-organization-submit'}
                    variant={'contained'}
                    color={'primary'}
                    fullWidth
                    type={'submit'}
                    disabled={isLoadingData}
                  >
                    <FormattedMessage
                      id="KNOWLEDGE_BASE.VIEW.MODAL.ADD_NEW_ORG.ACTION.SUBMIT"
                      defaultMessage="Add Organization"
                    />
                  </AnvButton>
                </Grid>
                <Grid item xs={12}>
                  <AnvButton
                    data-test={'add-new-organization-cancel'}
                    variant={'text'}
                    customColor={'muted'}
                    fullWidth
                    onClick={handleToggleFilters}
                    disabled={isLoadingData}
                  >
                    <FormattedMessage
                      id="KNOWLEDGE_BASE.VIEW.MODAL.ADD_NEW_ORG.ACTION.CLOSE"
                      defaultMessage="Close"
                    />
                  </AnvButton>
                </Grid>
              </Grid>
            </AnvDialogActions>
          </form>
        </FormProvider>
      </AnvDialog>
    );
  },
);
