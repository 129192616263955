import React from 'react';
import { Grid } from '@material-ui/core';
import { AnvIcon } from '@platform/front-lib';

import { FormattedMessage } from 'react-intl';
interface IProps {
  published?: boolean;
}
const CustomPublishedRow: React.FC<IProps> = ({ published }) => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <AnvIcon
          color={published ? 'success' : 'error'}
          icon={published ? 'eyeOutline' : 'eyeOff'}
          size={published ? 20 : 18}
        />
      </Grid>
      <Grid item>
        {published ? (
          <FormattedMessage
            id="KNOWLEDGE_BASE.COMMON.LIST.PUBLISHED"
            defaultMessage="Published"
          />
        ) : (
          <FormattedMessage
            id="KNOWLEDGE_BASE.COMMON.LIST.NOT_PUBLISHED"
            defaultMessage="Not Published"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CustomPublishedRow;
