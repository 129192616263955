export const KNOWLEDGE_BASE_MESSAGES = {
  //common
  COMMON_FORM_URL_HELPER_TEXT: {
    id: 'KNOWLEDGE_BASE.COMMON.FORM.URL.HELPER_TEXT',
    defaultMessage:
      'Reserved code to identify the first part of the URL. For example: anvilar',
  },
  //base
  KNOWLEDGE_BASE_FORM_DEFAULT_TITLE_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.KNOWLEDGE_BASE.FORM.DEFAULT_TITLE.TEXT',
    defaultMessage: 'New Knowledge Base',
  },
  KNOWLEDGE_BASE_LABEL: {
    id: 'KNOWLEDGE_BASE.BUILD.KNOWLEDGE_BASE.LIST.LABEL',
    defaultMessage: 'Knowledge bases',
  },
  KNOWLEDGE_BASE_ADD: {
    id: 'KNOWLEDGE_BASE.BUILD.KNOWLEDGE_BASE.LIST.ACTION.ADD_BASE',
    defaultMessage: 'Add knowledge base',
  },
  KNOWLEDGE_BASE_PUBLISH_ERROR_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.KNOWLEDGE_BASE.PUBLISH.ERROR.TEXT',
    defaultMessage:
      'To publish this knowledge base it should contain at least one published tutorial',
  },
  KNOWLEDGE_BASE_UNPUBLISH_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.KNOWLEDGE_BASE.UNPUBLISH.CONFIRM.TEXT',
    defaultMessage: 'Are you sure you want to unpublish this knowledge base?',
  },
  KNOWLEDGE_BASE_PUBLISH_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.KNOWLEDGE_BASE.PUBLISH.CONFIRM.TEXT',
    defaultMessage: 'Are you sure you want to publish this knowledge base?',
  },
  KNOWLEDGE_DELETE_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.KNOWLEDGE_BASE.DELETE.CONFIRM.TEXT',
    defaultMessage: 'Are you sure you want to delete the knowledge base?',
  },

  //TUTORIALS
  TUTORIAL_FORM_DEFAULT_TITLE_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.FORM.DEFAULT_TITLE.TEXT',
    defaultMessage: 'New tutorial',
  },
  DELETE_BASE: {
    id: 'KNOWLEDGE_BASE.BUILD.BASE.LIST.ACTION.DELETE',
    defaultMessage: 'Delete',
  },
  DELETE_TUTORIAL: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.LIST.ACTION.DELETE',
    defaultMessage: 'Delete',
  },
  PUBLISH_BASE: {
    id: 'KNOWLEDGE_BASE.BUILD.BASE.LIST.ACTION.PUBLISH',
    defaultMessage: 'Publish',
  },
  PUBLISH_TUTORIAL: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.LIST.ACTION.PUBLISH',
    defaultMessage: 'Publish',
  },
  UNPUBLISH_BASE: {
    id: 'KNOWLEDGE_BASE.BUILD.BASE.LIST.ACTION.UNPUBLISH',
    defaultMessage: 'Unpublish',
  },
  UNPUBLISH_TUTORIAL: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.LIST.ACTION.UNPUBLISH',
    defaultMessage: 'Unpublish',
  },
  PREVIEW_BASE: {
    id: 'KNOWLEDGE_BASE.BUILD.BASE.LIST.ACTION.PREVIEW',
    defaultMessage: 'Preview',
  },
  PREVIEW_TUTORIAL: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.LIST.ACTION.PREVIEW',
    defaultMessage: 'Preview',
  },
  GENERAL_INFO: {
    id: 'KNOWLEDGE_BASE.BUILD.BASE.LIST.ACTION.GENERAL_INFO',
    defaultMessage: 'General Information',
  },
  CONTENT: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.LIST.ACTION.CONTENT',
    defaultMessage: 'Content',
  },
  INFORMATION: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.LIST.ACTION.INFORMATION',
    defaultMessage: 'Information',
  },
  TUTORIALS: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.LIST.ACTION.TUTORIALS',
    defaultMessage: 'Tutorials',
  },
  TUTORIAL_ADD: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.LIST.ACTION.ADD_TUTORIAL',
    defaultMessage: 'Add tutorial',
  },
  TUTORIAL_DELETE_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.DELETE.CONFIRM.TEXT',
    defaultMessage: 'Are you sure you want to unpublish this tutorial?',
  },
  TUTORIAL_UNPUBLISH_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.UNPUBLISH.CONFIRM.TEXT',
    defaultMessage: 'Are you sure you want to unpublish this tutorial?',
  },
  TUTORIAL_PUBLISH_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.PUBLISH.CONFIRM.TEXT',
    defaultMessage: 'Are you sure you want to publish this tutorial?',
  },
  TUTORIAL_PUBLISH_ERROR_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.TUTORIAL.PUBLISH.ERROR.TEXT',
    defaultMessage:
      'To publish this tutorial it should contain at least one published chapter or at least one published sub tutorial',
  },

  //CHAPTERS
  CHAPTER_FORM_DEFAULT_TITLE_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.CHAPTER.FORM.DEFAULT_TITLE.TEXT',
    defaultMessage: 'New Knowledge Base',
  },
  CHAPTER_ADD: {
    id: 'KNOWLEDGE_BASE.BUILD.CHAPTER.LIST.ACTION.ADD_CHAPTER',
    defaultMessage: 'Add chapter',
  },
  CHAPTER_DELETE_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.CHAPTER.DELETE.CONFIRM.TEXT',
    defaultMessage: 'Are you sure you want to delete the chapter?',
  },
  CHAPTER_UNPUBLISH_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.CHAPTER.UNPUBLISH.CONFIRM.TEXT',
    defaultMessage: 'Are you sure you want to unpublish this chapter?',
  },
  CHAPTER_FORM_UNPUBLISH_SAVE_TEXT: {
    id: 'KNOWLEDGE_BASE.BUILD.CHAPTER.UNPUBLISH.WARNING.TEXT',
    defaultMessage:
      'Changing the status of the chapter to unpublished you can change the status of the tutorial to unpublished. Do you want to save your changes?',
  },
};
