export const ROUTE_PREFIX = '';

const MODULE_BUILD_PREFIX = `${ROUTE_PREFIX}/build*`;
const MODULE_BUILD_ROOT = `${ROUTE_PREFIX}/build`;
const MODULE_BUILD_USER = `${ROUTE_PREFIX}/build`;

const MODULE_BUILD_OPERATOR = `${ROUTE_PREFIX}/build/knowledge-base`;

const MODULE_VIEW = `${ROUTE_PREFIX}/view`;

export const MODULE_VIEW_PUBLIC = '';
const MODULE_VIEW_TUTORIALS = `${ROUTE_PREFIX}${MODULE_VIEW_PUBLIC}`;

const MODULE_ACCESS = `${ROUTE_PREFIX}/access`;
export const ROUTE_ACCESS_PREFIX = `${MODULE_ACCESS}/:section?/:subSection?/:other?`;
const RELATED_LINK_DISPATCHER = `${ROUTE_PREFIX}/dispatch`;

// export const ROUTES: Record<string, string> = {
export const ROUTES = {
  ROOT: ROUTE_PREFIX,
  TEST: ROUTE_PREFIX + '/test',
  TEST_SUB: ROUTE_PREFIX + '/test/subTutorial',
  RELATED_LINK_DISPATCHER,
  /**auth */
  USER_AUTH: ROUTE_PREFIX + '/auth',
  AUTH_FAIL: ROUTE_PREFIX + '/auth-fail',
  AUTH_FORBIDDEN: ROUTE_PREFIX + '/forbidden',
  AUTH_LOGOUT: ROUTE_PREFIX + '/logout',

  /** build */
  MODULE_BUILD_PREFIX,

  MODULE_BUILD_ROOT,
  MODULE_BUILD: MODULE_BUILD_ROOT,

  /** build user */
  MODULE_BUILD_NEW: MODULE_BUILD_ROOT + '/new/:id',
  MODULE_BUILD_USER: MODULE_BUILD_USER,
  MODULE_BUILD_USER_LIST: MODULE_BUILD_USER + '/list',

  /** build operator & common */
  // MODULE_BUILD: MODULE_BUILD_OPERATOR,
  MODULE_BUILD_OPERATOR: MODULE_BUILD_OPERATOR,

  KNOWLEDGE_BASE_LIST: MODULE_BUILD_OPERATOR,
  KNOWLEDGE_BASE_FORM: MODULE_BUILD_OPERATOR + '/form/:id?',

  KNOWLEDGE_CHAPTER_LIST:
    MODULE_BUILD_OPERATOR + '/:baseId/:tutorialsId*/chapters',
  KNOWLEDGE_CHAPTER_FORM:
    MODULE_BUILD_OPERATOR + '/:baseId/:tutorialsId*/chapters/form/:chapterId?',

  KNOWLEDGE_TUTORIAL_LIST: MODULE_BUILD_OPERATOR + '/:baseId/:tutorialsId*',
  KNOWLEDGE_TUTORIAL_FORM:
    MODULE_BUILD_OPERATOR + '/:baseId/:tutorialsId*/form/:id?',

  KNOWLEDGE_BUILD_PREVIEW:
    MODULE_BUILD_OPERATOR + '/preview/:baseId/:tutorialsId*',

  /**view */
  MODULE_VIEW,
  VIEW_KNOWLEDGE_BASE_LIST: MODULE_VIEW + '/list',
  VIEW_KNOWLEDGE_BASE_NOT_FOUND: MODULE_VIEW + '/not-found/:educationEntityId',

  VIEW_KNOWLEDGE_TUTORIAL_LIST_ROOT: MODULE_VIEW_TUTORIALS + '/:baseId',

  VIEW_KNOWLEDGE_TUTORIAL_LIST:
    MODULE_VIEW_TUTORIALS + '/:baseId/:tutorialsId*',

  VIEW_KNOWLEDGE_CHAPTER_LIST:
    MODULE_VIEW_TUTORIALS + '/:baseId/:tutorialsId*/chapters',

  /**access */
  MODULE_ACCESS,
  ROUTE_ACCESS_PREFIX,
  ADMIN_ACCESS_LIST: MODULE_ACCESS + '/list',
  /**common */
  PUBLIC_TERMS: ROUTE_PREFIX + '/terms-conditions',
  PUBLIC_PRIVACY: ROUTE_PREFIX + '/privacy-policy',
  PUBLIC_CONTACT_US: ROUTE_PREFIX + '/contact-us',
};
type TRoutesKeys = keyof typeof ROUTES;

export const PUBLIC_VIEW_ROUTES = [
  ROUTES.VIEW_KNOWLEDGE_TUTORIAL_LIST,
  ROUTES.VIEW_KNOWLEDGE_TUTORIAL_LIST_ROOT,
  ROUTES.VIEW_KNOWLEDGE_CHAPTER_LIST,
  // ROUTES.VIEW_KNOWLEDGE_BASE_LIST,
];

export const NOT_PUBLIC_ROUTES: string[] = Object.keys(ROUTES)
  .map((routeKey) => {
    const key = (routeKey as unknown) as TRoutesKeys;
    if (PUBLIC_VIEW_ROUTES.indexOf(ROUTES[key]) > -1) {
      return ROUTES[key];
    }

    return null;
  })
  .filter((item) => !!item) as string[];
