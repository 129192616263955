import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';

import { generatePath, useParams } from 'react-router-dom';
import {
  AnvBreadcrumbs,
  AnvUtils,
  Constants,
  IsLoadingComponent,
  useViewError,
} from '@platform/front-lib';

import { useStores } from '../../../stores';

import { IKnowledgeParamsCommon } from '../../../models';
import { ModuleContext } from '../../common/module-context';

import {
  generateDrawerBase,
  getTutorialsFromSegments,
  prepareTutorialSegments,
} from '../../../utils';
import {
  blacklistSegmentTopLevel,
  ROUTES,
  TOOL_MODULES,
} from '../../../constants';
import { useIntl } from 'react-intl';

interface IKnowledgeContainerProps {}

export const KnowledgeContainer: React.FC<IKnowledgeContainerProps> = observer(
  ({ children }) => {
    const { locale, formatMessage } = useIntl();
    const {
      baseId,
      tutorialsId,
      id: tutorialFormId,
    } = useParams<IKnowledgeParamsCommon>();

    const { pathname } = useLocation();
    const firstSlug = pathname?.split('/')?.[1];
    let isPublicBaseDrawer =
      blacklistSegmentTopLevel?.indexOf(firstSlug) === -1;

    const tutorialsSegments = React.useMemo(() => {
      return prepareTutorialSegments({
        tutorialsId: tutorialsId,
        tutorialFormId: tutorialFormId,
      });
    }, [tutorialsId, tutorialFormId]);

    const [restoreData, setRestoreData] = React.useState(false);

    React.useEffect(() => {
      if (restoreData) {
        setRestoreData(false);
      }
    }, [restoreData]);

    const module = React.useContext(ModuleContext);
    const isBuildModule = module === TOOL_MODULES.BUILD;

    const {
      [isBuildModule ? 'buildStore' : 'viewStore']: {
        knowledgeBaseStore: {
          dataMap: knowledgeBaseMap,
          getOne: getOneKnowledgeBase,
          error: errorBase,
          resetErrors: resetBaseErrors,
        },

        tutorialsStore: {
          dataMap: tutorialsMap,
          dataMapId: tutorialsMapId,
          dataMapIdLink: tutorialsMapIdLink,
          getTutorialsChain,
          getTutorials,
          error: errorTutorial,
          resetErrors: resetTutorialErrors,

          isFetching: isFetchingTutorials,
        },
      },
      uiStores: { setCustomDrawerData, tool },
    } = useStores();

    const { view_type, ErrorView } = useViewError({
      errorItem: errorBase || errorTutorial,
      dropErrors: () => {
        resetBaseErrors?.();
        resetTutorialErrors?.();
      },

      actionText: formatMessage({
        id: 'COMMON.ACTION_UNAVAILABLE.ACTION',
        defaultMessage: 'Return to the main page',
      }),
      isActionRouteLocal: true,
      actionRoute: isBuildModule
        ? generatePath(ROUTES.MODULE_BUILD_USER_LIST)
        : generatePath(ROUTES.VIEW_KNOWLEDGE_BASE_LIST),
    });

    const tutorialsSegmentsEntities = getTutorialsFromSegments({
      knowledgeBaseUrlId: baseId,
      tutorialsMap,
      tutorialsSegments,
    });

    const isTutorialsSegmentsEntitiesLoaded =
      !(tutorialsId || tutorialFormId) ||
      (tutorialsSegmentsEntities?.length &&
        tutorialsSegmentsEntities.filter((item) => !item)?.length === 0);

    React.useEffect(() => {
      if (tutorialsSegments && !isTutorialsSegmentsEntitiesLoaded) {
        getTutorialsChain({
          baseId,
          tutorialsSegments: tutorialsSegments,
        });
      }
    }, [
      baseId,
      tutorialsSegments,
      isTutorialsSegmentsEntitiesLoaded,
      getTutorialsChain,
    ]);

    React.useEffect(() => {
      if (isPublicBaseDrawer) {
        getTutorials({
          baseId,
          withChildren: true,
          root: true,
        });
      }
    }, [baseId, isPublicBaseDrawer, getTutorials]);

    const baseEntity = knowledgeBaseMap[`${baseId}`];

    React.useEffect(() => {
      if (!baseEntity && !!baseId) {
        getOneKnowledgeBase({ id: baseId });
      }
    }, [baseId, baseEntity, getOneKnowledgeBase]);

    // todo check is all tutorials exists at  'tutorialsMapId'
    const SOME =
      baseEntity?.tutorials &&
      baseEntity.tutorials.every((tutorialId) => {
        return !!tutorialsMapId[tutorialId];
      });

    React.useEffect(() => {
      if (isPublicBaseDrawer && baseEntity?.tutorials && SOME) {
        const drawerData = generateDrawerBase({
          baseEntity,
          tutorialsMapIdLink,
          tutorialsMapId,
          locale,
        });

        if (drawerData?.length) {
          setCustomDrawerData(drawerData);
        }
      }
    }, [
      isFetchingTutorials,
      SOME,
      isPublicBaseDrawer,
      baseEntity,
      tutorialsMapIdLink,
      tutorialsMapId,
      setCustomDrawerData,
      locale,
    ]);

    if (
      ErrorView &&
      view_type === Constants.VIEW_ERROR.SCREEN &&
      (errorBase || errorTutorial)
    ) {
      //@ts-ignore
      const entityName = !!errorBase?.['educationEntityName']
        ? AnvUtils.getNameByLangNullable(
            errorBase,
            'educationEntityName',
            locale,
          ) || ''
        : null;

      return (
        <Fragment>
          {entityName && (
            <AnvBreadcrumbs
              tool={tool}
              moduleCode={undefined}
              items={[{ component: entityName }]}
            />
          )}

          {ErrorView}
        </Fragment>
      );
    }

    if (!(baseEntity && isTutorialsSegmentsEntitiesLoaded)) {
      return <IsLoadingComponent isLoading />;
    }

    return <>{children}</> || null;
  },
);
