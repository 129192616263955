import React from 'react';

import { IKnowledgeChapter } from '../../../../../models';
import AnvChaptersTabs from './chapter-tabs';
import { AnvKnowledgeChapter } from './chapter';

interface IProps {
  chapters?: IKnowledgeChapter[];
}

const AnvChapters: React.FC<IProps> = ({ chapters }) => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <AnvChaptersTabs
      chapters={chapters}
      tabValue={tabValue}
      handleChangeTab={handleChangeTab}
    >
      {!!chapters &&
        chapters.map((chapter, index) => {
          return (
            <AnvKnowledgeChapter
              key={chapter._id}
              setTabValue={setTabValue}
              chapter={chapter}
              index={index}
            />
          );
        })}
    </AnvChaptersTabs>
  );
};

export default AnvChapters;
