export const TOOL_CODE = 'KNOWLEDGE-BASE';

export const TOOL_MODULES = {
  VIEW: 'VIEW',
  BUILD: 'BUILD',
  ACCESS: 'ACCESS',
};

export const anvilarSegment = ['anvilar'];

export const blacklistSegment = ['form', 'list', 'chapters'];

export const blacklistSegmentTopLevel = [
  'auth',
  'auth-fail',
  'operator',
  'build',
  'preview',
  'view',
  'access',
  'subscriptions',
];
