import React from 'react';
import { observer } from 'mobx-react-lite';

import { useTheme, Theme } from '@material-ui/core';

import { AnvNotFoundPage } from '@platform/front-lib';

import { useStores } from '../stores';
import ContainerAuth from '../components/layout/container-auth';

const NotFoundPage = observer(() => {
  const {
    palette: { brand },
  } = useTheme<Theme>();

  const {
    authStores: { isOperatorLoggedIn },
  } = useStores();

  return (
    <ContainerAuth>
      <AnvNotFoundPage
        chainsColor={brand?.light}
        middleChainColor={brand?.main}
        numbersColor={brand?.main}
        isOperator={isOperatorLoggedIn}
      />
    </ContainerAuth>
  );
});

export default NotFoundPage;
