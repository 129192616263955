import React, { useEffect } from 'react';
import axios from 'axios';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/stores/root-store';
import LocalStorage from 'src/stores/_helpers/storage';
import browserLocale from './getLanguage';
import IsLoadingComponent from '../components/layout/loading';
import { envConst } from '../constants';
import { usePrevious } from '@platform/front-lib';

export const defaultLanguage = 'en';

// Fallback to English before defaultMessage
const messages = {
  en: {},
  es: {},
};

export const supportedLanguages = Object.keys(messages);

const isLanguageSupport = (
  lang: string | undefined | null,
  languages: Record<string, any>[] | null,
) => {
  if (languages) {
    return lang && languages?.map?.((item) => item.code).indexOf(lang) > -1;
  } else {
    return lang && supportedLanguages.indexOf(lang) > -1;
  }
};

export const IntlProvider: React.FC = observer(({ children }) => {
  const {
    authStores: { profile, profileOperator, isLoggedIn },
    uiStores: { languages, translations, setLanguages, setTranslations },
  } = useStores();

  let locale: string | undefined = defaultLanguage;

  if (isLoggedIn) {
    locale =
      profileOperator?.locale ||
      profile?.locale ||
      LocalStorage?.get('ui_locale') ||
      defaultLanguage;
  } else {
    locale = LocalStorage?.get('ui_locale') || defaultLanguage;
  }

  const [languagesFetching, setLanguagesFetching] = React.useState<boolean>(
    false,
  );

  const isLanguages = !!languages;

  const [translationsFetching, setTranslationsFetching] = React.useState(false);
  const [localStorageUpdated, setLocalStorageUpdated] = React.useState(false);

  const [languagesError, setLanguagesError] = React.useState(false);

  const prevIsAuthComplete = usePrevious(isLoggedIn);

  useEffect(() => {
    if (!!languages) {
      updateCurrentLanguage();
    }
    // eslint-disable-next-line
  }, [languages]);

  useEffect(() => {
    if (isLanguages) {
      updateCurrentLanguage();
    }
    if (
      isLanguages &&
      isLoggedIn &&
      // !prevIsAuthComplete &&
      !localStorageUpdated &&
      !translationsFetching
    ) {
      updateLocalStorage();
    }
    // eslint-disable-next-line
  }, [
    isLanguages,
    prevIsAuthComplete,
    isLoggedIn,
    translationsFetching,
    localStorageUpdated,
  ]);

  const getAllLanguages = React.useCallback(async () => {
    if (!languagesError) {
      try {
        const url = `${envConst.apiUrl}/meta/languages`;
        const langs = await axios.get(url);

        if (langs?.data) {
          setLanguages(langs?.data);
        }
      } catch (e) {
        setLanguagesError(true);
        console.log('--e--', { e });
      }
      setLanguagesFetching(false);
    }
  }, [languagesError, setLanguages]);

  React.useEffect(() => {
    if (!languages && !languagesFetching) {
      setLanguagesFetching(true);
      setImmediate(getAllLanguages);
    }
  }, [languages, languagesFetching, setLanguagesFetching, getAllLanguages]);

  const updateLocalStorage = async () => {
    setTranslationsFetching(true);
    const url = `${envConst.apiUrl}/meta/translations`;

    const translationsRes = await axios.get(url);
    const translationsLoc = translationsRes?.data;

    const newState: Record<string, any> = {};
    languages?.forEach(({ code }) => {
      if (translationsLoc[code]) {
        let messages = translationsLoc[code];

        LocalStorage.setJSON(`locale_${code}`, messages);

        newState[code] = messages;
      } else {
        LocalStorage.setJSON(`locale_${code}`, {});
      }
    });

    setLocalStorageUpdated(true);

    setTranslations({ ...translations, ...newState });

    setTranslationsFetching(false);
    updateCurrentLanguage();
  };

  const updateCurrentLanguage = () => {
    // const langCode = _getCurrentLanguage(locale);
    // if (
    //   (document && langCode && document.documentElement.lang !== langCode) ||
    //   !translations?.[langCode]
    // ) {
    //   LocalStorage.set('ui_locale', langCode);
    //
    //   if (!translations?.[langCode]) {
    //     try {
    //       const newState: Record<string, any> = {};
    //       let messages = LocalStorage.getJSON(`locale_${langCode}`);
    //       console.log('--messages--', { messages, langCode });
    //
    //       // load if local storage empty
    //       if (!messages) {
    //         updateLocalStorage();
    //         return;
    //       }
    //
    //       newState[langCode] = messages;
    //
    //       if (
    //         langCode !== defaultLanguage &&
    //         !translations?.[defaultLanguage]
    //       ) {
    //         let messagesDefault = LocalStorage.getJSON(
    //           `locale_${defaultLanguage}`,
    //         );
    //
    //         newState[defaultLanguage] = messagesDefault;
    //
    //         if (!messagesDefault) {
    //           updateLocalStorage();
    //           return;
    //         }
    //       }
    //
    //       setTranslations({ ...translations, ...newState });
    //
    //       return;
    //     } catch (e) {}
    //   }
    //
    //   document.documentElement.lang = langCode;
    // }
  };

  const _getCurrentLanguage = React.useCallback(
    (definedLocale: string | undefined): string => {
      if (definedLocale && isLanguageSupport(definedLocale, languages)) {
        return definedLocale;
      }

      if (locale && isLanguageSupport(locale, languages)) {
        return locale;
      }

      const localeStorageLocale = LocalStorage.get('ui_locale');

      if (
        localeStorageLocale &&
        isLanguageSupport(localeStorageLocale, languages)
      ) {
        return localeStorageLocale;
      }

      if (isLanguageSupport(browserLocale, languages)) {
        return browserLocale;
      }

      return defaultLanguage || 'en';
    },
    [languages, locale],
  );

  const lang = React.useMemo(() => {
    return _getCurrentLanguage(locale);
  }, [locale, _getCurrentLanguage]);
  // date-fns
  window.__localeId__ = lang;

  const actualMessages = React.useMemo(() => {
    let actualMessagesLoc = translations?.[lang] || null;

    if (lang !== defaultLanguage) {
      actualMessagesLoc = Object.assign(
        {},
        translations?.[defaultLanguage] || {},
        actualMessagesLoc,
      );
    }

    return actualMessagesLoc;
  }, [lang, translations]);

  const isLoading = languagesFetching || translationsFetching;

  return (
    <ReactIntlProvider
      locale={lang}
      messages={actualMessages || {}}
      defaultLocale={defaultLanguage}
    >
      <IsLoadingComponent isLoading={isLoading} />
      {children}
      {/*{isLoading ? <IsLoadingComponent isLoading={isLoading} /> : children}*/}
    </ReactIntlProvider>
  );
});
