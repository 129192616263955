import React from 'react';
import {
  AccessStore,
  AuthStore,
  MetaStores,
  UiStore,
  UserStore,
} from '@platform/front-lib';
// import UiStore from './ui/ui-store';

import { BuildStore } from './knowledge/build';
import { ViewStore } from './knowledge/view';
import { TOOL_MODULES } from '../constants';

const initAuthSore = new AuthStore(TOOL_MODULES);

export const rootStore = {
  userStore: new UserStore(),
  uiStores: UiStore,
  authStores: initAuthSore,
  accessStore: AccessStore,
  metaStores: MetaStores,
  buildStore: BuildStore,
  viewStore: ViewStore,
};

export const StoresContext = React.createContext(rootStore);

export const useStores = () => React.useContext(StoresContext);
