import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { APP_BAR_HEIGHT } from '../../../../../../styles/constants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,

      display: 'flex',
      height: 'inherit',

      right: 40,

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    childrenWrapper: {
      marginRight: 72,
      [theme.breakpoints.down('sm')]: {
        marginRight: 'unset',
      },
    },
    tabsWrapper: {
      position: 'sticky',
      top: APP_BAR_HEIGHT + 16,

      maxHeight: `calc(100vh - ${APP_BAR_HEIGHT + 16}px)`,
    },
    tabsWrapperNav:{
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },

    tabs: {
      maxHeight: `calc(100vh - ${APP_BAR_HEIGHT + 56 + 16}px)`,

      borderLeft: `1px solid ${theme.palette.divider}`,
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.brand.main,
        left: 0,
      },
      '& .MuiTab-root': {
        width: 215,
        minHeight: 40,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        lineHeight: '22px',

        letterSpacing: '-0.04px',
      },
      '& .MuiTab-wrapper': {
        alignItems: 'flex-start',
      },
    },

    tabLink: {
      color: 'inherit',
      textDecoration: 'unset',
      textAlign: 'left',
    },

    drawerLink: {
      '&:first-child': {
        borderTop: '1px solid rgba(3, 57, 61, 0.15)',
      },
      borderRadius: 'unset',
      padding: '16px 0px !important',
      borderBottom: '1px solid rgba(3, 57, 61, 0.15)',

      color: 'inherit',
      textDecoration: 'unset',
      textAlign: 'left',
      justifyContent: 'space-between',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
    },
    stickyButton: {
      justifyContent: 'flex-start',
      padding: theme.spacing(2, 0, 2, 0),
      marginBottom: theme.spacing(2),
      position: 'sticky',
      top: 64,
      backgroundColor: 'white',
    },
  }),
);
