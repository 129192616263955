import { action, makeObservable } from 'mobx';
import { ICommonService, IKnowledgeChapter } from 'src/models';

import { ChaptersService } from '../../../../service/knowledge';
import { IActionMeta } from '../../../_helpers';
import { CommonChaptersStore } from '../../chapters-common';

class BuildChaptersStore extends CommonChaptersStore {
  chaptersService: ChaptersService;

  create = async (payload: IKnowledgeChapter, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.chaptersService.create(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.data?.push(response);
      this.dataMap[response._id] = response;

      meta?.successFunc?.();
    });
  };

  update = async (payload: IKnowledgeChapter, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.chaptersService.update(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.();
    });
  };

  publish = async (
    {
      id,
    }: {
      id: string;
    },
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.chaptersService.publish({ id });

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.data =
        this.data &&
        this.data.map((item) => {
          if (item._id === id) {
            return {
              ...item,
              published: true,
            };
          }

          return item;
        });

      meta?.successFunc?.();
    });
  };

  unpublish = async (
    {
      id,
    }: {
      id: string;
    },
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.chaptersService.unpublish({ id });

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.data =
        this.data &&
        this.data.map((item) => {
          if (item._id === id) {
            return {
              ...item,
              published: false,
            };
          }

          return item;
        });

      meta?.successFunc?.();
    });
  };

  delete = async (
    payload: { id: string; parentId: string },
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.chaptersService.delete(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.data =
        this.data && this.data.filter((item) => item._id !== payload.id);
      meta?.successFunc?.();
    });
  };

  updatePositions = async (
    payload: IKnowledgeChapter[],
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.chaptersService.updatePositions(
      payload,
    );

    if (error || response?.error) {
      meta?.failFunc?.(error);
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.data =
        this.data &&
        this.data
          .map((existItem) => {
            const replace = response.filter(
              (responseItem: IKnowledgeChapter) =>
                responseItem._id === existItem._id,
            );
            if (!!replace.length) {
              return replace[0];
            }
            return existItem;
          })
          .sort((a: any, b: any) => a?.position - b?.position);
      meta?.successFunc?.(response);
    });
  };

  constructor({ isView }: ICommonService) {
    super({ isView });
    this.chaptersService = new ChaptersService({ isView });

    makeObservable(this, {
      create: action,
      update: action,
      delete: action,
      publish: action,
      unpublish: action,
      updatePositions: action,
    });
  }
}

export default new BuildChaptersStore({ isView: false });
