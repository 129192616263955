import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    chaptersWrapper: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
  }),
);
