import React from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/stores/root-store';
import {
  AnvAppBar,
  AnvDrawerOperator,
  AnvToolDisabledPage,
  AnvUtils,
  Constants,
  ContainerView,
  IsLoadingComponent,
  OperatorNavbarCenter,
} from '@platform/front-lib';
import { USER_ROLES } from '../../../constants/auth';
import { drawerAllEntries } from '../../../constants/drawer-map';
import { getCookieToken } from '../../../utils/cookie';
import { useInterval, useQuery } from '../../../utils/hooks';
import { blacklistSegmentTopLevel, envConst, ROUTES } from '../../../constants';
import { useLocation } from 'react-router';
import { AnvDrawerBase } from '../../../pages/common/drawer-base/AnvDrawerBase';
import { useRouteMatch } from 'react-router-dom';

const ContainerConnected: React.FC = observer(({ children }) => {
  const {
    authStores: {
      isOperatorLoggedInPrev,
      token,
      tokenOperator,
      profile,
      profileOperator,
      isLoggedIn,
      isOperatorLoggedIn,
      getMyModules,
      logOut,
      setActiveLocale,
      modulesCodes,
      updateDataFromCookie,
      silentReturnUrl,
    },
    uiStores: {
      drawerOpen,
      toggleDrawerOpen,
      languages,
      tool,
      customDrawerData,
    },

    metaStores,
  } = useStores();

  const {
    toolsStore: {
      getTools,
      data: tools,
      dataMap: toolsMapClean,
      isLoaded: isLoadedTools,
    },
  } = metaStores;

  const isAuthFailRoute = useRouteMatch(ROUTES.AUTH_FAIL)?.isExact;
  const query = useQuery();

  if (isAuthFailRoute) {
    const queryReturnUrl = (!silentReturnUrl && query?.returnUrl) || '';

    const launchpadUrl = AnvUtils.cleanUrl(`${envConst?.launchpadUrlLogOut}/`);

    window.location.href =
      (isOperatorLoggedInPrev &&
        envConst.tools[Constants.TOOLS.SUPPORT]?.front) ||
      '' ||
      `${launchpadUrl}${queryReturnUrl ? '?returnUrl=' + queryReturnUrl : ''}`;
    window.location.href = `${
      envConst?.launchpadUrlLogOut || envConst?.launchpadUrl
    }/${queryReturnUrl ? '?returnUrl=' + queryReturnUrl : ''}`;
  }

  const { pathname } = useLocation();
  const firstSlug = pathname?.split('/')?.[1];
  const isPublicBaseDrawer =
    blacklistSegmentTopLevel?.indexOf(firstSlug) === -1;

  const toolsMap = React.useMemo(() => {
    return toJS(toolsMapClean);
    // force update props for drawer
    // eslint-disable-next-line
  }, [tools, toolsMapClean]);

  React.useEffect(() => {
    if (isOperatorLoggedIn && !isLoadedTools) {
      getTools();
    }
  }, [isOperatorLoggedIn, isLoadedTools, getTools]);

  useInterval(() => {
    if (
      (getCookieToken() !== token && (getCookieToken() || token)) ||
      (getCookieToken({ isOperator: true }) !== tokenOperator &&
        (getCookieToken({ isOperator: true }) || tokenOperator))
    ) {
      updateDataFromCookie();
    }
  }, 2000);

  React.useEffect(() => {
    if (isLoggedIn && !isPublicBaseDrawer) {
      getMyModules();
    }
  }, [isLoggedIn, isPublicBaseDrawer, isOperatorLoggedIn, getMyModules]);

  if (!isPublicBaseDrawer && tool?.disabled && !isOperatorLoggedIn) {
    return <AnvToolDisabledPage themeColor withWrapper />;
  }

  const header = (
    <AnvAppBar
      profile={profileOperator}
      isLoggedIn={isOperatorLoggedIn}
      toggleDrawerOpen={toggleDrawerOpen}
      drawerOpen={drawerOpen}
      logOut={logOut}
      customCenterTitle={<OperatorNavbarCenter />}
      isOperator={isOperatorLoggedIn}
    />
  );

  const drawer = isOperatorLoggedIn ? (
    <AnvDrawerOperator
      drawerData={Constants.drawerOperator}
      toolsMap={toolsMap}
      profile={profile}
      logOut={logOut}
      drawerOpen={drawerOpen}
      toggleDrawerOpen={toggleDrawerOpen}
      setActiveLocale={setActiveLocale}
      languages={languages}
    />
  ) : isPublicBaseDrawer ? (
    <AnvDrawerBase drawerData={customDrawerData || []} />
  ) : undefined;

  return (
    <ContainerView
      metaStores={metaStores}
      header={isOperatorLoggedIn ? header : undefined}
      forceShowDrawerToggler={isPublicBaseDrawer}
      drawer={drawer}
      profile={profileOperator || profile}
      isLoggedIn={isLoggedIn}
      logOut={logOut}
      drawerOpen={drawerOpen}
      toggleDrawerOpen={toggleDrawerOpen}
      languages={languages}
      setActiveLocale={(locale: string) => {
        return setActiveLocale(locale);
      }}
      modulesCodes={modulesCodes}
      // @ts-ignore
      drawerData={drawerAllEntries[USER_ROLES.ROLE_CLIENT]}
      // @ts-ignore
      tool={tool}
      // footerHideLangMenu={isPublicBaseDrawer}
      // footerJustify={isPublicBaseDrawer ? undefined : 'space-between'}
      footerJustify={'space-between'}
    >
      {isOperatorLoggedIn && !isLoadedTools ? (
        <IsLoadingComponent isLoading />
      ) : (
        children
      )}
    </ContainerView>
  );
});

export default ContainerConnected;
