const APP_STORAGE_PREFIX = '';

class LocalStorage {
  static get(key: string) {
    return localStorage.getItem(`${APP_STORAGE_PREFIX}${key}`) || null;
  }

  static getJSON(
    key: string,
  ): Record<string, any> | Record<string, any>[] | null {
    const item = localStorage.getItem(`${APP_STORAGE_PREFIX}${key}`);

    if (item) {
      try {
        return JSON.parse(item);
      } catch (e) {
        return null;
      }
    }

    return null;
  }

  static set(key: string, value: string) {
    return localStorage.setItem(`${APP_STORAGE_PREFIX}${key}`, value);
  }

  static setJSON(
    key: string,
    value: Record<string, any> | Record<string, any>[] | null,
  ) {
    if (value) {
      localStorage.setItem(
        `${APP_STORAGE_PREFIX}${key}`,
        JSON.stringify(value),
      );
    }
  }

  static remove(key: string) {
    return localStorage.removeItem(`${APP_STORAGE_PREFIX}${key}`);
  }

  clear() {
    return localStorage.clear();
  }
}

export default LocalStorage;
