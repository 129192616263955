import { makeStyles } from '@material-ui/core';

import { BORDER_RADIUS } from 'src/styles/constants';
import { ICommonTheme } from '../../../../../../models';

interface IStyleProps {
  toolColors?: ICommonTheme | null;
  theme: any;
}

const CARD_BOX_SHADOW = ' 0px 1px 0px #D9D9D9, 0px 0px 0px 1px #E1E1E1';

export const useStylesRootCardCommon = makeStyles({
  root: ({ theme, toolColors }: IStyleProps) => ({
    '&&': {
      padding: 0,
    },

    marginBottom: theme.spacing(1),
    width: '100%',

    borderRadius: BORDER_RADIUS,
    boxShadow: CARD_BOX_SHADOW,

    '&.active': {
      backgroundColor: toolColors?.brandColorLight,
      borderRadius: BORDER_RADIUS,
    },

    '&:last-child': {
      marginBottom: 0,
    },
  }),

  leftBlock: ({ theme, toolColors }: IStyleProps) => ({
    width: theme.spacing(9),

    backgroundColor: toolColors?.brandColor || theme.palette.brand.main,
    color:
      toolColors?.brandColorLight ||
      theme.palette.common.toolColors?.brandColorDark,
    borderRadius: `${BORDER_RADIUS}px 0px 0px ${BORDER_RADIUS}px`,
  }),

  iconWrapper: ({ theme }: IStyleProps) => ({
    padding: theme.spacing(3),
  }),

  contentWrapper: ({ theme }: IStyleProps) => ({
    position: 'relative',

    padding: theme.spacing(3),
    flex: 1,
  }),
  contentWrapperCollapsible: {
    cursor: 'pointer',
  },

  collapseButton: ({ theme }: IStyleProps) => ({
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
  }),

  link: {
    textDecoration: 'none',
  },
  linkChevron: {
    marginLeft: 2,
    display: 'inline-block',
    height: 24,
  },
  linkText: ({ theme }) => {
    return {
      display: 'inline-flex',
      alignItems: 'center',

      lineHeight: '24px',
      color: theme.palette.text.primary,

      '&:hover': {
        color: theme.palette.brand.main,
      },
    };
  },
});
