import { makeObservable } from 'mobx';
import { KnowledgeBaseService } from '../../../../service/knowledge';
import { ICommonService } from '../../../../models';
import { CommonBaseStore } from '../../base-common';

class ViewBaseStore extends CommonBaseStore {
  baseService: KnowledgeBaseService;

  constructor({ isView }: ICommonService) {
    super({ isView });
    this.baseService = new KnowledgeBaseService({ isView });

    makeObservable(this, {});
  }
}

export default new ViewBaseStore({ isView: true });
