import { IPagination } from './interfaces';
import { PAGINATION_LIMIT } from '../../constants';

export const defaultPagination: IPagination = {
  limit: PAGINATION_LIMIT,
  offset: 0,
  page: 0,
  totalDocs: 0,
  totalPages: 0,
};
