import React from 'react';
import { AnvUtils } from '@platform/front-lib';
import { generatePath } from 'react-router-dom';
import {
  blacklistSegment,
  blacklistSegmentTopLevel,
  MODULE_VIEW_PUBLIC,
  ROUTES,
} from '../constants';
import { IKnowledgeBase, IKnowledgeTutorial } from '../models';
import { FormattedMessage } from 'react-intl';
import { IBaseDrawerItem } from '../pages/common/drawer-base/interfaces';

const notValidUrl = 'Not valid username';

export const validateUrl = (
  value: string | null,
  isTopLevel: boolean = false,
) => {
  if (!value) {
    return notValidUrl;
  }

  if (!/^['_\-0-9a-zA-Z]+$/.test(value)) {
    return notValidUrl;
  }

  if (isTopLevel && blacklistSegmentTopLevel.indexOf(value) > -1) {
    return notValidUrl;
  }

  if (!isTopLevel && blacklistSegment.indexOf(value) > -1) {
    return notValidUrl;
  }

  return true;
};

export function urlReplaceForm(string: string, removePostfix = false) {
  if (removePostfix) {
    return string.replace(/\/form\/.*$/g, '');
  }
  return string.replace(/\/form/g, '');
}

export function urlReplacer({
  url,
  remove,
  removeAllAfter = false,
}: {
  url: string;
  remove: string;
  removeAllAfter?: boolean;
}) {
  const re = new RegExp(remove, 'g');
  if (removeAllAfter) {
  }
  return url.replace(re, '');
}

export function findTutorialParentId({
  baseId,
  tutorialsId = '',
}: any): string {
  const tutorialIdPrepare = tutorialsId?.split('/') || [];
  const tutorialParentId =
    tutorialIdPrepare?.[tutorialIdPrepare?.length - 1] || '';

  if (!!tutorialIdPrepare.length && !!tutorialParentId) {
    return tutorialParentId;
  }

  return baseId;
}

export function findTutorialParentId2({
  baseId,
  tutorialsId = '',
}: {
  baseId: string;
  tutorialsId: string;
}): string {
  const tutorialIdPrepare = prepareTutorialSegments({ tutorialsId }) || [];
  tutorialIdPrepare.unshift(baseId);
  return tutorialIdPrepare.join('/');
}

export function prepareTutorialSegments({
  tutorialsId,
  tutorialFormId,
}: {
  tutorialsId: string;
  tutorialFormId?: string;
}) {
  if (!(tutorialsId || tutorialFormId)) {
    return null;
  }
  const segments: string[] = [];

  const tutorialsIdToArray = tutorialsId?.split('/') || [];

  if (tutorialFormId) {
    tutorialsIdToArray.push(tutorialFormId);
  }

  tutorialsIdToArray.forEach((item: string) => {
    if (blacklistSegment.includes(item)) {
      return;
    }

    segments.push(item);
  });

  if (segments.length > 0) {
    return segments;
  }

  return null;
}

export function customGeneratePath({
  urlPattern,
  knowledgeBaseId,
  language,
  tutorialId,
}: any): string {
  return urlPattern
    .replace(/:baseId/g, knowledgeBaseId)
    .replace(/:language/g, language)
    .replace(/:tutorialsId/g, tutorialId)
    .replace(/\*/g, '');
}

interface IGenerateDrawerBase {
  baseEntity: IKnowledgeBase;
  tutorialsMapIdLink: Record<string, string>;
  tutorialsMapId: Record<string, IKnowledgeTutorial>;

  locale: string;
}
interface IGetTutorialsFromSegments {
  knowledgeBaseUrlId: string;

  tutorialsSegments?: string[] | null;
  tutorialsMap: Record<string, IKnowledgeTutorial>;
}
interface IPrepareBreadCrumbsItemsParams {
  knowledgeBaseUrlId: string;

  tutorialsUrlsId?: string;
  knowledgeBaseMap: Record<string, IKnowledgeBase>;
  tutorialsMap: Record<string, IKnowledgeTutorial>;

  isView?: boolean;
  isPreview?: boolean;
  isChapters?: boolean;
  isForm?: boolean;
  locale: string;
}
interface IPrepareBreadCrumbsItemsResult {
  component: React.ReactElement | string;
  to?: string;
}

export function prepareBreadCrumbsItems({
  knowledgeBaseUrlId,
  tutorialsUrlsId,
  knowledgeBaseMap,
  tutorialsMap,

  isView,
  isChapters,
  isForm,
  isPreview,
  locale,
}: IPrepareBreadCrumbsItemsParams): IPrepareBreadCrumbsItemsResult[] {
  const items: IPrepareBreadCrumbsItemsResult[] = [];
  const baseKey = `${knowledgeBaseUrlId}`;

  const knowledgeBaseEntity = knowledgeBaseMap[baseKey];

  if (!!knowledgeBaseEntity) {
    const elem = {
      component: isView ? (
        AnvUtils.getNameByLangNullable(knowledgeBaseEntity, 'title', locale) ||
        ''
      ) : (
        <FormattedMessage
          id="KNOWLEDGE_BASE.BUILD.BREADCRUMBS.TUTORIAL_LIST"
          defaultMessage="Tutorial List"
        />
      ),
    };

    if (!!tutorialsUrlsId) {
      items.push({
        ...elem,
        to: generatePath(
          isView
            ? ROUTES.VIEW_KNOWLEDGE_TUTORIAL_LIST
            : isPreview
            ? ROUTES.KNOWLEDGE_BUILD_PREVIEW
            : ROUTES.KNOWLEDGE_TUTORIAL_LIST,
          {
            baseId: knowledgeBaseUrlId,
          },
        ),
      });
    } else {
      if (isForm && !tutorialsUrlsId) {
        items.push({
          ...elem,
          to: generatePath(ROUTES.KNOWLEDGE_TUTORIAL_LIST, {
            baseId: knowledgeBaseUrlId,
          }),
        });

        items.push({
          component: (
            <FormattedMessage
              id="KNOWLEDGE_BASE.COMMON.BREADCRUMBS.FORM.TEXT"
              defaultMessage="Form"
            />
          ),
        });
      } else {
        items.push(elem);
      }
    }
  }

  if (!!tutorialsUrlsId) {
    const tutorialsSegmentsArray =
      prepareTutorialSegments({
        tutorialsId: tutorialsUrlsId,
      }) || [];

    tutorialsSegmentsArray.forEach((item: string, index: number) => {
      const totalId = tutorialsSegmentsArray.length;
      const isLastItem = totalId === index + 1;

      const key = [baseKey, ...tutorialsSegmentsArray.slice(0, index + 1)].join(
        '/',
      );
      const tutorialEntity = tutorialsMap[key];

      if (!tutorialEntity) {
        return;
      }

      const tutorialIds = tutorialsSegmentsArray.slice(0, index + 1).join('/');

      const pathTo = customGeneratePath({
        urlPattern: isView
          ? ROUTES.VIEW_KNOWLEDGE_TUTORIAL_LIST
          : isPreview
          ? ROUTES.KNOWLEDGE_BUILD_PREVIEW
          : ROUTES.KNOWLEDGE_TUTORIAL_LIST,
        knowledgeBaseId: knowledgeBaseUrlId,
        tutorialId: tutorialIds,
      });

      const tutorialTitle = AnvUtils.getNameByLangNullable(
        tutorialEntity,
        'title',
        locale,
      );

      if (isLastItem) {
        if (isForm) {
          items.push({
            // @ts-ignore
            component: tutorialTitle || '',
            to: pathTo,
          });
          items.push({
            // @ts-ignore
            component: (
              <FormattedMessage
                id="KNOWLEDGE_BASE.COMMON.BREADCRUMBS.FORM.TEXT"
                defaultMessage="Form"
              />
            ),
          });
        } else {
          items.push({
            // @ts-ignore
            component: tutorialTitle || '',
          });
        }
      } else {
        items.push({
          // @ts-ignore
          component: tutorialTitle || '',
          to: pathTo,
        });
      }
    });
  }

  return items;
}

export function getTutorialsFromSegments({
  knowledgeBaseUrlId,

  tutorialsSegments,
  tutorialsMap,
}: IGetTutorialsFromSegments): IKnowledgeTutorial[] {
  const items: IKnowledgeTutorial[] = [];

  const baseKey = `${knowledgeBaseUrlId}`;

  if (tutorialsSegments?.length) {
    tutorialsSegments.forEach((item: string, index: number) => {
      if (blacklistSegment.indexOf(item) > -1) {
        return;
      }

      const key = [baseKey, ...tutorialsSegments.slice(0, index + 1)].join('/');
      const tutorialEntity = tutorialsMap[key];

      items.push(tutorialEntity);
    });
  }

  return items;
}

export const generateDrawerBase = ({
  baseEntity,
  tutorialsMapIdLink,
  tutorialsMapId,
  locale,
}: IGenerateDrawerBase): IBaseDrawerItem[] => {
  return (
    (((baseEntity.tutorials &&
      baseEntity.tutorials
        .map((item) => {
          const tutorial = tutorialsMapId[item];

          if (tutorial?._id) {
            const tutorialLink = tutorialsMapIdLink[tutorial._id];

            if (tutorialLink) {
              const item: IBaseDrawerItem = {
                key: tutorialLink,
                name:
                  AnvUtils.getNameByLangNullable(tutorial, 'title', locale) ||
                  '',
                nameI18n: '',
                to: tutorialLink,
                icon: tutorial.icon || 'dotsGrid',
                iconColor: tutorial.theme?.brandColor,
                iconColorLight: tutorial.theme?.brandColorLight,
                position: tutorial.position,
              };

              if (tutorial.tutorials) {
                /** 2 lvl only */
                const subItems = tutorial.tutorials
                  .map((subItem) => {
                    const tutorialSub = tutorialsMapId[subItem];

                    if (tutorialSub?._id) {
                      const tutorialSubLink =
                        tutorialsMapIdLink[tutorialSub._id];

                      if (tutorialSubLink) {
                        const subItem: IBaseDrawerItem = {
                          key: tutorialSubLink,
                          name:
                            AnvUtils.getNameByLangNullable(
                              tutorialSub,
                              'title',
                              locale,
                            ) || '',
                          nameI18n: '',
                          to: MODULE_VIEW_PUBLIC + tutorialSubLink,
                          icon: tutorialSub.icon || 'dotsGrid',
                          position: tutorialSub.position,
                        };

                        return subItem;
                      }
                    }
                    return null;
                  })
                  .filter((item) => !!item) as IBaseDrawerItem[];

                /** 2 ^ */
                if (subItems?.length) {
                  item.items = subItems || [];
                }
              }

              return item;
            }
          }

          return undefined;
        })
        .filter((item) => !!item)) ||
      []) as IBaseDrawerItem[]) || []
  );
};
