import React from 'react';

import { Tabs, Tab, Grid, Divider, Hidden } from '@material-ui/core';
import slugify from '@sindresorhus/slugify';

import { AnvButton, AnvIcon, AnvUtils, TextBody } from '@platform/front-lib';
import { FormattedMessage, useIntl } from 'react-intl';
import { IKnowledgeChapter } from '../../../../../../models';
import { useStyles } from './styles';
import MobileDrawer from '../../../../../../components/layout/mobile-markup/components/mobile-drawer';
import { AnvChipUnpublished } from '../../../../../common/chip-unpublished';
import { ModuleContext } from '../../../../../common/module-context';
import { TOOL_MODULES } from '../../../../../../constants';

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface IProps {
  chapters?: IKnowledgeChapter[];
  handleChangeTab: (event: React.ChangeEvent<{}>, value: any) => void;
  tabValue: number;
}

const AnvChaptersTabs: React.FC<IProps> = ({
  children,
  tabValue,
  handleChangeTab,
  chapters,
}) => {
  const { locale } = useIntl();
  const classes = useStyles();
  const [opeDrawer, setOpenDrawer] = React.useState(false);

  const module = React.useContext(ModuleContext);
  const isBuildModule = module === TOOL_MODULES.BUILD;

  const [variant, setVariant] = React.useState<
    'standard' | 'scrollable' | 'fullWidth'
  >('scrollable');

  React.useLayoutEffect(() => {
    setVariant('standard');
  }, []);

  const toggleDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };
  const currentChapter = chapters?.[tabValue - 1];

  return (
    <>
      <Grid container direction="row" wrap={'nowrap'}>
        <Grid item xs={12} className={classes.childrenWrapper}>
          <Hidden mdUp>
            <AnvButton
              fullWidth
              onClick={toggleDrawer}
              variant={'text'}
              startIcon={
                <AnvIcon icon={'dotsGrid'} size={24} color={'primary'} />
              }
              className={classes.stickyButton}
            >
              {!!currentChapter && tabValue > 0 ? (
                <span style={{ textAlign: 'left' }}>
                  <FormattedMessage
                    id="KNOWLEDGE_BASE.VIEW.CHAPTER.CHAPTER"
                    defaultMessage="Chapter"
                  />
                  :{' '}
                  {AnvUtils.getNameByLangNullable(
                    currentChapter,
                    'title',
                    locale,
                  )}
                </span>
              ) : (
                <FormattedMessage
                  id="KNOWLEDGE_BASE.VIEW.CHAPTER.CHAPTERS"
                  defaultMessage="Chapters"
                />
              )}
            </AnvButton>
          </Hidden>
          {children}
        </Grid>

        <Grid item className={classes.root}>
          <div className={classes.tabsWrapper}>
            <nav className={classes.tabsWrapperNav}>
              <TextBody fontWeight={600}>
                <FormattedMessage
                  id="KNOWLEDGE_BASE.VIEW.CHAPTER.CHAPTERS"
                  defaultMessage="Chapters"
                />
              </TextBody>
            </nav>

            <Tabs
              orientation="vertical"
              value={tabValue}
              onChange={handleChangeTab}
              className={classes.tabs}
              variant={variant}
              scrollButtons="on"
            >
              {chapters &&
                chapters?.map((chapter, index) => {
                  const anchorId = slugify(`${index}-${chapter.title?.en}`);

                  return (
                    <Tab
                      key={`${chapter._id} `}
                      label={
                        <a className={classes.tabLink} href={`#${anchorId}`}>
                          {AnvUtils.getNameByLangNullable(
                            chapter,
                            'title',
                            locale,
                          )}
                          {!chapter?.published && isBuildModule && (
                            <AnvChipUnpublished short />
                          )}
                        </a>
                      }
                      {...a11yProps(index)}
                    />
                  );
                })}
            </Tabs>
          </div>
        </Grid>
      </Grid>

      <MobileDrawer
        open={opeDrawer}
        onClose={toggleDrawer}
        handleClose={toggleDrawer}
        anchor={'bottom'}
        title={
          <FormattedMessage
            id="KNOWLEDGE_BASE.VIEW.CHAPTER.CHAPTERS"
            defaultMessage="Chapters"
          />
        }
      >
        <Divider style={{ margin: 0 }} />
        {chapters &&
          chapters?.map((chapter, index) => {
            const anchorId = slugify(`${index}-${chapter.title?.en}`);

            return (
              <AnvButton
                //@ts-ignore
                component={'a'}
                onClick={toggleDrawer}
                key={`${chapter._id} `}
                className={classes.drawerLink}
                href={`#${anchorId}`}
                variant={'text'}
                endIcon={
                  <AnvIcon
                    icon={'chevron'}
                    size={24}
                    color={'lightIcons'}
                    style={{ transform: 'rotate(90deg)' }}
                  />
                }
              >
                {AnvUtils.getNameByLangNullable(chapter, 'title', locale)}
              </AnvButton>
            );
          })}
      </MobileDrawer>
    </>
  );
};

export default AnvChaptersTabs;
