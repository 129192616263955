import { action, makeObservable } from 'mobx';
import { ICommonService, IKnowledgeTutorial } from 'src/models';

import { TutorialsService } from '../../../../service/knowledge';
import { IActionMeta } from '../../../_helpers';
import { CommonTutorialsStore } from '../../tutorials-common';

interface IKnowledgeTutorialNew extends IKnowledgeTutorial {
  parentId: string;
}

class BuildTutorialsStore extends CommonTutorialsStore {
  tutorialService: TutorialsService;

  create = async (payload: IKnowledgeTutorialNew, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.tutorialService.create(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.data?.push(response);
      this.dataMap[response.urlSegment] = response;
      meta?.successFunc?.();
    });
  };

  update = async (payload: IKnowledgeTutorial, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.tutorialService.update(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.();
    });
  };

  publish = async (
    {
      id,
      entityKey,
      isInList = false,
      isCurrentEntity = false,
    }: {
      id: string;
      entityKey: string;
      isCurrentEntity?: boolean;
      isInList?: boolean;
    },
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.tutorialService.publish(id);

    if (error || response?.error) {
      meta?.failFunc?.();
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }
    this.setLoaded(() => {
      if (!!this.dataMap && !!this.dataMap[entityKey] && isCurrentEntity) {
        this.dataMap[entityKey].published = true;
      }

      if (isInList && !!this.data) {
        this.data = this.data.map((item) => {
          if (item._id === id) {
            return {
              ...item,
              published: true,
            };
          }

          return item;
        });
      }
      meta?.successFunc?.();
    });
  };

  checkPublish = async (
    {
      id,
    }: {
      id: string;
    },
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.tutorialService.checkPublish({ id });

    if (error || response?.error) {
      meta?.failFunc?.();
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }
    this.setLoaded(() => {
      this.publishEnable = response.publishedEnable;
      meta?.successFunc?.();
    });
  };

  unpublish = async (
    {
      id,
      entityKey,
      isInList = false,
      isCurrentEntity = false,
    }: {
      id: string;
      entityKey: string;
      isCurrentEntity?: boolean;
      isInList?: boolean;
    },
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.tutorialService.unpublish(id);

    if (error || response?.error) {
      meta?.failFunc?.();
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      if (!!this.dataMap && !!this.dataMap[entityKey] && isCurrentEntity) {
        this.dataMap[entityKey].published = false;
      }

      if (isInList && !!this.data) {
        this.data = this.data.map((item) => {
          if (item._id === id) {
            return {
              ...item,
              published: false,
            };
          }

          return item;
        });
      }

      meta?.successFunc?.();
    });
  };

  updatePositions = async (
    payload: IKnowledgeTutorial[],
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.tutorialService.updatePositions(
      payload,
    );

    if (error || response?.error) {
      meta?.failFunc?.(error);
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.data =
        this.data &&
        this.data
          .map((existItem) => {
            const replace = response.filter(
              (responseItem: IKnowledgeTutorial) =>
                responseItem._id === existItem._id,
            );
            if (!!replace.length) {
              return replace[0];
            }
            return existItem;
          })
          .sort((a: any, b: any) => a?.position - b?.position);

      meta?.successFunc?.(response);
    });
  };

  delete = async (
    payload: { id: string; parentId: string },
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.tutorialService.delete(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.data =
        this.data && this.data.filter((item) => item._id !== payload.id);
      meta?.successFunc?.();
    });
  };

  constructor({ isView }: ICommonService) {
    super({ isView: true });
    this.tutorialService = new TutorialsService({ isView });

    makeObservable(this, {
      create: action,
      update: action,
      publish: action,
      unpublish: action,
      updatePositions: action,
      delete: action,
    });
  }
}

export default new BuildTutorialsStore({ isView: false });
