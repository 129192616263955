import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStylesContent = makeStyles((theme) =>
  createStyles({
    contentWrapper: {
      padding: '0 24px 16px 24px',
    },
    itemCommon: {
      marginBottom: theme.spacing(1.5),
    },
    contentWrapperCommon: {
      marginTop: theme.spacing(1.5),
    },

    link: {
      fontFamily: 'Inter',
      fontSize: 13,
      lineHeight: '22px',
      letterSpacing: '-0.18px',
      color: theme.palette.text.body,
      textDecoration: 'none',
    },

    linkCommon: {
      display: 'inline-flex',
      alignItems: 'center',

      color: theme.palette.text.primary,
      textDecoration: 'none',
      fontWeight: 600,

      '&:hover': {
        color: theme.palette.brand.main,
      },
    },
    linkChevron: {
      marginLeft: 2,
      display: 'inline-block',
      height: 16,
    },

    viewAll: {
      marginTop: theme.spacing(1.5),
      paddingLeft: theme.spacing(0.5),
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: 13,
      lineHeight: '22px',
      letterSpacing: '-0.04px',
      color: theme.palette.brand.main,
      textDecoration: 'none',
    },

    viewAllCommon: {
      marginTop: 0,
    },
  }),
);
