import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../stores';

import { envConst } from 'src/constants';
import { useQuery } from 'src/utils/hooks';
import { AnvUtils, Constants } from '@platform/front-lib';


const RedirectSourceSystemFail = observer(() => {
  const {
    authStores: { silentReturnUrl, isOperatorLoggedInPrev },
  } = useStores();

  const query = useQuery();
  const queryReturnUrl = (!silentReturnUrl && query?.returnUrl) || '';

  const launchpadUrl = AnvUtils.cleanUrl(`${envConst?.launchpadUrlLogOut}/`);

  window.location.href =
    (isOperatorLoggedInPrev &&
      envConst.tools[Constants.TOOLS.SUPPORT]?.front) ||
    '' ||
    `${launchpadUrl}${queryReturnUrl ? '?returnUrl=' + queryReturnUrl : ''}`;

  return (
    <div className="login__container">
      <div className="login__form">
        {/*<FormattedMessage*/}
        {/*  id="APP.PERMISSION_DENIED"*/}
        {/*  defaultMessage="Permission Denied"*/}
        {/*/>*/}
      </div>
    </div>
  );
});

export default RedirectSourceSystemFail;
