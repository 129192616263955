import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Drawer, DrawerProps, Grid, IconButton } from '@material-ui/core';
import { AnvIcon, TextH3 } from '@platform/front-lib';

const useStyles = makeStyles((theme) =>
  createStyles({
    rootDrawer: {
      borderRadius: '16px 16px 0px 0px',
      padding: theme.spacing(3, 3, 5, 3),
    },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    drawerRectangle: {
      backgroundColor: ' #dadce0',
      borderRadius: ' 50px',
      height: '4px',
      margin: '8px auto 8px auto',
      width: '24px',
    },
  }),
);
interface IProps extends DrawerProps {
  title?: any;
  handleClose: () => void;
}

const MobileDrawer: React.FC<IProps> = ({
  children,
  handleClose,
  title,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Drawer classes={{ paper: classes.rootDrawer }} {...rest}>
      <Grid
        container
        direction={'row'}
        wrap={'nowrap'}
        alignItems={'center'}
        justify={'space-between'}
        style={{ position: 'relative', marginBottom: 28 }}
      >
        <Grid>{title && <TextH3>{title}</TextH3>} </Grid>
        <IconButton
          style={{ position: 'absolute', right: -12, top: -8 }}
          onClick={handleClose}
        >
          <AnvIcon icon={'closeCross'} size={24} color={'lightIcons'} />
        </IconButton>
      </Grid>
      {children}
    </Drawer>
  );
};

export default MobileDrawer;
